import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import * as R from "ramda";
import React, {
  PropsWithChildren,
  Reducer,
  useCallback,
  useEffect,
  useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";
import { ITheme, Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import RAFDatePicker from "../../../RAFComponents/Inputs/RAFDatePicker";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import { getFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFIconImage from "../../../RAFComponents/Navigation/RAFIconImage";
import {
  getAllFormsByCategoryTypes,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import {
  Guid,
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import { customTheme } from "../../../RAFMaster/RMModules/FormLibrary/components/custom-theme";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  RAFButtonConstant,
  RAFLayout,
} from "../../../constants/Common/Constants";
import { RAFBPCurrentStatus } from "../../../constants/Common/RMConstants";
import { getAllStandardizedLibraryItemsByType } from "../CareInterventionSupport/CareInterventionSupportHelper";
import {
  PublishCareAssessment,
  SaveCareAssessment,
} from "./CareAssessmentHelper";

interface IProps {
  isActive: boolean;
  moduleName: string;
  onSave: (entityId?: string, objectName?: string) => void;
  onClose: () => void;
  initialValues?: any;
  careAssessments?: any;
  careRecipientUID?: string;
  onCreatCareAssessment: () => void;
}

interface IState {
  isLoading: boolean;
  initialValues: any;
  selectedItemIndex: number;
  selectedFormEntityName: string;
  allForms: FormLibraryRow[];
  saveAndPublishPromptContent: boolean;
  isSaveAndNew: boolean;
  careAssessments: any;
  selectedFormLibrary: FormLibraryRow;
  formModel: any;
  allStandardizedLibraryItems: any[];
}

function CreateCareAssessment({ ...props }: PropsWithChildren<IProps>) {
  let rafForm: FormRenderProps | null;
  let rafReviewForm: FormRenderProps | null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      selectedItemIndex: 0,
      initialValues: null,
      selectedFormEntityName: null,
      allForms: null,

      saveAndPublishPromptContent: false,
      isSaveAndNew: false,
      careAssessments: isNotEmptyArray(props.careAssessments)
        ? props.careAssessments
        : [],
      selectedFormLibrary: null,
      formModel: null,
      allStandardizedLibraryItems: null,
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    if (props.isActive) {
      setState({
        isLoading: true,
        selectedItemIndex: 0,
        initialValues: null,
        selectedFormEntityName: null,
        allForms: null,

        saveAndPublishPromptContent: false,
        isSaveAndNew: false,
        careAssessments: isNotEmptyArray(props.careAssessments)
          ? props.careAssessments
          : [],
        selectedFormLibrary: null,
        formModel: null,
        allStandardizedLibraryItems: null,
      });
      let initalObject = {};
      if (isNotNullAndUndefined(props.initialValues)) {
        initalObject = props.initialValues;
      }

      const [allForms, allStandardizedLibraryItems] = await Promise.all([
        getAllFormsByCategoryTypes(
          [CareEsioEntity.CareAssessment.DisplayName],
          CareEsioEntity.CareAssessment.EntityName
        ),
        getAllStandardizedLibraryItemsByType("Activity"),
      ]);
      const filteredForms = isNotEmptyArray(allForms)
        ? allForms.filter((x) => x.Entity === "care_section_plan")
        : [];

      let selectedItemIndex: 0 | 1 | 2 = 0;
      let selectedFormLibrary = null;
      let selectedFormEntityName = null;
      let formModel = null;
      if (isNotEmptyArray(filteredForms) && filteredForms.length === 1) {
        selectedItemIndex = 1;
        selectedFormLibrary = filteredForms[0];
        selectedFormEntityName = filteredForms[0].Entity;
        formModel = getFormDataModel(filteredForms[0]);
      } else if (isNotEmptyArray(filteredForms) && filteredForms.length > 1) {
        selectedItemIndex = 0;
      }

      if (isNotEmptyArray(allStandardizedLibraryItems)) {
        let newNode: { UID: string; Title: string; } = {
          UID: Guid.newGuid(),
          Title: "Others",
        };
        allStandardizedLibraryItems.push(newNode as never);
      }

      setState({
        initialValues: initalObject,
        selectedItemIndex,
        isLoading: false,
        allForms: filteredForms,
        selectedFormEntityName,
        selectedFormLibrary,
        formModel,
        allStandardizedLibraryItems,
      });
    }
  };

  const refreshonCreate = async () => {
    if (props.isActive) {
      if (props.onCreatCareAssessment) {
        props.onCreatCareAssessment();
      }
      setState({
        isLoading: true,
        selectedItemIndex: 0,
        initialValues: null,
        selectedFormEntityName: null,
        allForms: null,

        saveAndPublishPromptContent: false,
        isSaveAndNew: false,
        careAssessments: isNotEmptyArray(props.careAssessments)
          ? props.careAssessments
          : [],
        selectedFormLibrary: null,
        formModel: null,
        allStandardizedLibraryItems: null,
      });

      let initalObject = {};
      if (isNotNullAndUndefined(props.initialValues)) {
        initalObject = props.initialValues;
      }

      const [allForms, allStandardizedLibraryItems] = await Promise.all([
        getAllFormsByCategoryTypes(
          [CareEsioEntity.CareAssessment.DisplayName],
          CareEsioEntity.CareAssessment.EntityName
        ),
        getAllStandardizedLibraryItemsByType("Activity"),
      ]);

      const filteredForms = isNotEmptyArray(allForms)
        ? allForms.filter((x) => x.Entity === "care_section_plan")
        : [];

      let selectedItemIndex: 0 | 1 | 2 = 0;
      let selectedFormLibrary = null;
      let selectedFormEntityName = null;
      let formModel = null;
      if (isNotEmptyArray(filteredForms) && filteredForms.length === 1) {
        selectedItemIndex = 1;
        selectedFormLibrary = filteredForms[0];
        selectedFormEntityName = filteredForms[0].Entity;
        formModel = getFormDataModel(filteredForms[0]);
      } else if (isNotEmptyArray(filteredForms) && filteredForms.length > 1) {
        selectedItemIndex = 0;
      }

      if (isNotEmptyArray(allStandardizedLibraryItems)) {
        let newNode: { UID: string; Title: string; } = {
          UID: Guid.newGuid(),
          Title: "Others",
        };
        allStandardizedLibraryItems.push(newNode as never);
      }

      setState({
        initialValues: initalObject,
        selectedItemIndex,
        isLoading: false,
        allForms: filteredForms,
        selectedFormEntityName,
        selectedFormLibrary,
        formModel,
        allStandardizedLibraryItems,
      });
    }
  };

  const submitFormData = () => {
    const {
      selectedFormEntityName,
      isSaveAndNew,
      selectedFormLibrary,
      formModel,
    } = state;

    const careAssessment =
      isNotNullAndUndefined(rafForm) && isNotNullAndUndefined(rafForm.values)
        ? rafForm.values
        : null;

    let objValues = R.clone(careAssessment);

    let hasCustomForm = false;
    if (
      isNotNullAndUndefined(selectedFormLibrary) &&
      isNotNullAndUndefined(selectedFormLibrary.FormStructure)
    ) {
      hasCustomForm = true;
    }

    if (hasCustomForm) {
      let isFormValid = formModel.validate(true, true);
      if (isFormValid) {
        let formData = R.clone(formModel.data);

        objValues = { ...objValues, ...formData };
        const current_status = objValues.current_status;

        delete objValues["tags_list_json"];

        let progressDiv = showProgress(
          `#raf_dlg_Outer_Div_${props.moduleName}`
        );

        let saveRequestData = getSaveRequest(objValues, objValues.UID);
        let entityName = selectedFormLibrary.Entity;
        saveRequestData.EntityName = entityName;

        SaveCareAssessment(saveRequestData, "DataList/SaveForm").then(
          async (response) => {
            if (current_status === RAFBPCurrentStatus.Published) {
              const responsePublish = await PublishCareAssessment(
                response,
                entityName
              );
            }
            hideProgress(progressDiv);
            if (isSaveAndNew === true) {
              refreshonCreate();
            } else {
              if (isNotNullAndUndefined(props.onSave)) {
                props.onSave();
              }
            }
          }
        );
      }
    } else {
      let progressDiv = showProgress(`#raf_dlg_Outer_Div_${props.moduleName}`);
      delete objValues["SubCategoryOption"];
      delete objValues["DueDate"];

      const current_status = objValues.current_status;

      let saveRequestData = getSaveRequest(objValues, objValues.UID);
      //saveRequestData.EntityUID = objEntity.UID;
      //if assessment have form post selectedFormEntityname , if not post "care_assessment"
      let entityName = isNotNullAndUndefined(selectedFormEntityName)
        ? selectedFormEntityName
        : CareEsioEntity.CareAssessment.EntityName;
      saveRequestData.EntityName = entityName;

      SaveCareAssessment(saveRequestData).then(async (response) => {
        if (current_status === RAFBPCurrentStatus.Published) {
          const responsePublish = await PublishCareAssessment(
            response,
            entityName
          );
        }
        hideProgress(progressDiv);
        if (isSaveAndNew === true) {
          refreshonCreate();
        } else {
          if (isNotNullAndUndefined(props.onSave)) {
            props.onSave();
          }
        }
      });
    }
  };

  const onClickBackBtn = () => {
    let { initialValues } = state;
    initialValues["title"] = null;
    initialValues["category"] = null;
    initialValues["categoryuid"] = null;
    setState({
      selectedItemIndex: state.selectedItemIndex - 1,
      initialValues,
    });
  };

  //Save and Publish prompt starts
  const saveAndPublishPromptDialogOpen = (isSaveAndNew) => {
    const { selectedFormLibrary, formModel } = state;
    let hasCustomForm = false;
    if (
      isNotNullAndUndefined(selectedFormLibrary) &&
      isNotNullAndUndefined(selectedFormLibrary.FormStructure)
    ) {
      hasCustomForm = true;
    }

    if (hasCustomForm) {
      const isFormValid = formModel.validate(true, true);
      if (isFormValid) {
        setState({ saveAndPublishPromptContent: true, isSaveAndNew });
      }
    } else {
      setState({ saveAndPublishPromptContent: true, isSaveAndNew });
    }
  };

  const saveAndPublishPromptDialogClose = () => {
    setState({ saveAndPublishPromptContent: false });
  };

  const onSelectActionStatus = (current_status: string) => {
    const { formModel } = state;

    const reviewDateFormValue = getFormValue(rafReviewForm, "review_date");
    formModel.setValue("review_date", reviewDateFormValue);
    formModel.setValue("current_status", current_status);
    setState({ formModel, saveAndPublishPromptContent: false });
    submitFormData();
  };

  const onSaveActionStatus = (current_status: string) => {
    const { formModel } = state;
    formModel.setValue("current_status", current_status);
    setState({ formModel });
    submitFormData();
  };

  const saveAndPublishContent = () => {
    if (state.saveAndPublishPromptContent === true) {
      const initialValues = getFormValue(rafForm);
      return (
        <RAFForm
          initialValues={initialValues}
          formRef={(g) => {
            return (rafReviewForm = g);
          }}
          layout={RAFLayout.TwoColumnLayout}
          submitOnEnterKey={false}
        >
          <div className="e-dlg-body-content">
            <div className="row gy-3 g-0">
              <div className="col-12">
                <RAFDatePicker
                  field="review_date"
                  label="Review Date"
                  description="Specify the date for reviewing the progress and effectiveness of the interventions or action plan"
                />
              </div>
            </div>
          </div>
          <div className="e-dlg-footerContent pb-2-5">
            <ButtonComponent
              type="button"
              onClick={() => onSelectActionStatus(RAFBPCurrentStatus.Draft)}
              cssClass="btn_brand_primary semi_dark form-custom-button"
            >
              Save
            </ButtonComponent>
            {false && (
              <>
                <ButtonComponent
                  type="button"
                  onClick={() => onSelectActionStatus(RAFBPCurrentStatus.Draft)}
                  cssClass="form-custom-button"
                >
                  Save as Draft
                </ButtonComponent>
                <ButtonComponent
                  type="button"
                  cssClass="btn_brand_primary semi_dark form-custom-button"
                  onClick={() =>
                    onSelectActionStatus(RAFBPCurrentStatus.Published)
                  }
                >
                  Save & Publish
                </ButtonComponent>
              </>
            )}
          </div>
        </RAFForm>
      );
    } else {
      return <div></div>;
    }
  };

  //Save and Publish prompt ends

  const getFormContent = () => {
    return getNeedContent();
  };

  const getNeedContent = () => {
    const { selectedFormLibrary, formModel } = state;

    if (
      isNotNullAndUndefined(selectedFormLibrary) &&
      isNotNullAndUndefined(formModel)
    ) {
      let formData = R.clone(formModel.data);
      let title = formData.title;
      if (isNullOrUndefined(title)) {
        formModel.setValue("title", state.initialValues.title);
      }
      return (
        <div className="row gy-4 g-0 flex-column text-align-center form-group-margin-0">
          <RAFForm
            initialValues={state.initialValues}
            formRef={(g) => {
              return (rafForm = g);
            }}
            layout={RAFLayout.TwoColumnLayout}
            submitOnEnterKey={false}
          //onSubmit={onClickSaveBtn}
          >
            <Survey model={formModel} />
          </RAFForm>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const getFormDataModel = (selectedFormLibrary?: FormLibraryRow) => {
    if (isNotNullAndUndefined(selectedFormLibrary)) {
      let hasCustomForm = false;
      if (isNotNullAndUndefined(selectedFormLibrary.FormStructure)) {
        hasCustomForm = true;
      }
      let formModel = new Model(selectedFormLibrary?.FormStructure);

      formModel.applyTheme(customTheme as ITheme);
      let nextButton = formModel.navigationBar.getActionById("sv-nav-next");
      let prevButton = formModel.navigationBar.getActionById("sv-nav-prev");
      let completeButton =
        formModel.navigationBar.getActionById("sv-nav-complete");
      nextButton.visible = false;
      prevButton.visible = false;
      completeButton.visible = false;

      formModel.onErrorCustomText.add(function (sender, options) {
        if (options.name == "required") {
          //options.text = options.obj.title + " is required";
          options.text = "This field is required";
        }
      });

      return formModel;
    }
  };

  const getFooterContent = () => {
    const { selectedItemIndex } = state;
    if (selectedItemIndex === 2) {
      return (
        <div className="e-dlg-footerContent">
          <div className="w-100">
            <div className="row gx-2 justify-content-between align-items-center">
              <div className="col-auto">
                <RAFButtonComponent
                  type="button"
                  action={RAFButtonConstant.Back}
                  onClick={() => {
                    onClickBackBtn();
                  }}
                  className="btn_brand_secondary"
                  {...(BrowserIsDevice ? { iconBtn: true } : {})}
                />
              </div>
              {/* <div className="col-auto">
                <div className="row gx-2">
                  <div className="col">
                    <RAFButtonComponent
                      type="button"
                      onClick={() => saveAndPublishPromptDialogOpen(false)}
                      idString="saveAndClose"
                      className="w-100 e-outline"
                      showIcon={false}
                      btnContent="Save & Close"
                    />
                  </div>
                  <div className="col">
                    <RAFButtonComponent
                      type="button"
                      isPrimary
                      onClick={() => saveAndPublishPromptDialogOpen(true)}
                      idString="saveAndPublish"
                      className="w-100"
                      btnContent="Save & Add New"
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-auto">
                <ButtonComponent
                  type="button"
                  onClick={() => onSaveActionStatus(RAFBPCurrentStatus.Draft)}
                  cssClass="form-custom-button"
                >
                  Save as Draft
                </ButtonComponent>
                {false && (
                  <ButtonComponent
                    type="button"
                    cssClass="btn_brand_primary semi_dark form-custom-button"
                    onClick={() =>
                      onSaveActionStatus(RAFBPCurrentStatus.Published)
                    }
                  >
                    Save & Publish
                  </ButtonComponent>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const onClickCategoryItem = (item) => {
    const { initialValues } = state;
    initialValues["title"] = item.Title;
    initialValues["category"] = item.Title;
    if (item.Title !== "Others") {
      initialValues["categoryuid"] = item.UID;
    }
    setState({ selectedItemIndex: 2, initialValues });
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      const { allForms, selectedItemIndex, allStandardizedLibraryItems } =
        state;
      const allFormsLength = isNotEmptyArray(allForms) ? allForms.length : 0;

      return (
        <div
          className="h-100"
          key={props.moduleName}
          id={`raf_dlg_Outer_Div_${props.moduleName}`}
        >
          <div className="e-dlg-content-outer">
            <div className="e-dlg-body-content">
              {selectedItemIndex === 1 && (
                <>
                  {isNotEmptyArray(allStandardizedLibraryItems) && (
                    <div className="row g-3">
                      <div className="col-auto">
                        <div className="header-text-lg">
                          Please select the category you'd like to add?
                        </div>
                      </div>
                      {allStandardizedLibraryItems.map((item) => {
                        return (
                          <div className="col-md-12" key={item.UID}>
                            <div
                              className={`custom__card clickable`}
                              id={`card__${item.UID}`}
                              onClick={() => onClickCategoryItem(item)}
                            >
                              <div className="custom__card__content p-2 darkText">
                                <div className="d-flex align-items-center">
                                  <RAFIconImage
                                    iconCssClass={
                                      "far fa-hand-holding-seedling"
                                    }
                                    moduleavatar="transparent-avatar avatar-text avatar-text-square"
                                    iconSize="30"
                                    fontSize="16"
                                  />
                                  <span className="secondary-header-bold-text-withoutColor ps-2 ecllipseSecondLine">
                                    {item.Title}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}

              {selectedItemIndex === 2 && getFormContent()}
            </div>
            {(selectedItemIndex === 2 ||
              (selectedItemIndex === 1 && allFormsLength > 1)) &&
              getFooterContent()}
          </div>

          {state.saveAndPublishPromptContent && (
            <DialogComponent
              header={"Save and Publish"}
              showCloseIcon
              visible={state.saveAndPublishPromptContent}
              cssClass="centerDialog-sm createEditForm form-center-dialog"
              id="completeStepAlertDialogContent"
              content={saveAndPublishContent.bind(this)}
              isModal
              target="body"
              closeOnEscape={false}
              close={saveAndPublishPromptDialogClose.bind(this)}
              zIndex={1200}
              open={PreventFocusOnDialogOpen}
            //animationSettings={this.animationSettings}
            ></DialogComponent>
          )}
        </div>
      );
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  }
}

export default React.memo(CreateCareAssessment);
