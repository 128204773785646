import { ContentType } from "../../../constants/Common/Constants";
import {
  getAllForms,
  getFields,
  RAFActionMessage,
  RetrieveRecord,
} from "../../../RAFComponents/helpers/AppHelper";
import {
  isNotNullAndUndefined,
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";

export function getAssessmentFormsByCategoryId(categoryUID?: string) {
  return new Promise<FormLibraryRow[]>(async (resolve, reject) => {
    let assessmentForms: FormLibraryRow[] = [];
    const allForms = await getAllForms();
    let assessmentForm: FormLibraryRow = isNotNullAndUndefined(allForms)
      ? allForms.find((x) => x.CategoryUID === categoryUID)
      : null;
    if (isNotNullAndUndefined(assessmentForm)) {
      assessmentForms.push({ ...assessmentForm });
    }
    resolve(assessmentForms);
  });
}

export const getAssessmentListByCarePlanAndRecipientUID = (
  carePlanUID?: string,
  careRecipientUID?: string,
  relatedEntity?: string,
  current_status?: string
) => {
  return new Promise<any[]>((resolve, reject) => {
    if (isNotNullAndUndefined(relatedEntity)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      if (IsNotNullOrWhiteSpace(carePlanUID)) {
        let filter1: RAFCustomFilter = {};
        let filterVal1: string[] = [];
        filterVal1.push(carePlanUID);
        filter1.Operator = RAFCustomOperator.Equal;
        filter1.Value = filterVal1;
        filter1.Field = "care_planuid";
        relatedFilter.Rules.push(filter1);
      }

      if (IsNotNullOrWhiteSpace(careRecipientUID)) {
        let filter2: RAFCustomFilter = {};
        let filterVal2: string[] = [];
        filterVal2.push(careRecipientUID);
        filter2.Operator = RAFCustomOperator.Equal;
        filter2.Value = filterVal2;
        filter2.Field = "care_recipientuid";
        relatedFilter.Rules.push(filter2);
      }

      if (IsNotNullOrWhiteSpace(current_status)) {
        let filter3: RAFCustomFilter = {};
        let filterVal3: string[] = [];
        filterVal3.push(current_status);
        filter3.Operator = RAFCustomOperator.Equal;
        filter3.Value = filterVal3;
        filter3.Field = "current_status";
        relatedFilter.Rules.push(filter3);
      }

      let filter4: RAFCustomFilter = {};
      let filterVal4: string[] = [];
      filterVal4.push("true");
      filter4.Operator = RAFCustomOperator.Equal;
      filter4.Value = filterVal4;
      filter4.Field = "is_active";
      relatedFilter.Rules.push(filter4);

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      let sortBy = {
        field: "sub_category",
        order: "ascending",
      };
      let sortQuery: string[] = [];
      sortQuery.push(
        sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
      );

      listServiceRequest.Sort = sortQuery;

      listServiceRequest.EntityName = relatedEntity;

      repositoryActions
        .postDataAndGetResponse(
          "DataList/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entity)
            ) {
              resolve(response.data.Entity);
            } else if (
              response.data &&
              response.data.ResultTable &&
              response.data.ResultTable.length > 0
            ) {
              resolve(response.data.ResultTable);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const getAssessmentListByCareRecipientUID = (
  careRecipientUID: string,
  relatedEntity: string
) => {
  return new Promise<[]>((resolve, reject) => {
    if (isNotNullAndUndefined(relatedEntity)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      if (IsNotNullOrWhiteSpace(careRecipientUID)) {
        let filter1: RAFCustomFilter = {};
        let filterVal1: string[] = [];
        filterVal1.push(careRecipientUID);
        filter1.Operator = RAFCustomOperator.Equal;
        filter1.Value = filterVal1;
        filter1.Field = "care_recipientuid";
        relatedFilter.Rules.push(filter1);
      }

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      listServiceRequest.EntityName = relatedEntity;

      repositoryActions
        .postDataAndGetResponse(
          "DataList/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entity)
            ) {
              resolve(response.data.Entity);
            } else if (
              response.data &&
              response.data.ResultTable &&
              response.data.ResultTable.length > 0
            ) {
              resolve(response.data.ResultTable);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const getAssessmentListByCarePlanId = (
  activeCarePlanId: string,
  relatedEntityName: string
) => {
  return new Promise<{}>((resolve, reject) => {
    if (isNotNullAndUndefined(relatedEntityName)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      if (IsNotNullOrWhiteSpace(activeCarePlanId)) {
        let filter1: RAFCustomFilter = {};
        let filterVal1: string[] = [];
        filterVal1.push(activeCarePlanId);
        filter1.Operator = RAFCustomOperator.Equal;
        filter1.Value = filterVal1;
        filter1.Field = "care_planuid";
        relatedFilter.Rules.push(filter1);
      }

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      listServiceRequest.EntityName = relatedEntityName;

      repositoryActions
        .postDataAndGetResponse(
          "DataList/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entity)
            ) {
              resolve(response.data.Entity);
            } else if (
              response.data &&
              response.data.ResultTable &&
              response.data.ResultTable.length > 0
            ) {
              resolve(response.data.ResultTable);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const getDefaultCareAssessmentFieldValue = (careAssessmentRow) => {
  return new Promise<string>(async (resolve) => {
    let defaultFieldValue;
    if (isNotNullAndUndefined(careAssessmentRow)) {
      const moduleName = careAssessmentRow["entity"];

      if (isNotNullAndUndefined(moduleName)) {
        const fieldAttributes: QueryAttributeJM[] = await getFields(moduleName);
        const defaultField = isNotNullAndUndefined(fieldAttributes)
          ? fieldAttributes.find((x) => x.IsDefault === true)
          : null;

        defaultFieldValue = isNotNullAndUndefined(defaultField)
          ? careAssessmentRow[defaultField.PropertyName]
          : null;

        if (IsNullOrWhiteSpace(defaultFieldValue)) {
          const careAssessment = await RetrieveRecord(
            careAssessmentRow.UID,
            moduleName
          );

          defaultFieldValue = isNotNullAndUndefined(defaultField)
            ? careAssessment[defaultField.PropertyName]
            : null;

          if (IsNullOrWhiteSpace(defaultFieldValue)) {
            defaultFieldValue = careAssessment["assessment_type"];
          }
        }
      }
      if (IsNullOrWhiteSpace(defaultFieldValue)) {
        defaultFieldValue = careAssessmentRow["assessment_type"];
      }
    }
    resolve(defaultFieldValue);
  });
};

export const SaveCareAssessment = (saveRequestData?: any, url?: string) => {
  let saveUrl = url ?? "datalist/save";
  return new Promise<string>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        saveUrl,
        saveRequestData,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          resolve(response.data.EntityId);
        } else {
          showWarningToast(RAFActionMessage.RecordNotSaved);
          resolve(null);
        }
      })
      .catch((error) => error);
  });
};

export const PublishCareAssessment = (
  objectId?: string,
  entityName?: string
) => {
  return new Promise<string>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "datalist/publish",
        {
          EntityId: objectId,
          EntityName: entityName,
        },
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          resolve(response.data.EntityId);
        } else {
          showWarningToast(RAFActionMessage.RecordNotSaved);
          resolve(null);
        }
      })
      .catch((error) => error);
  });
};

export const getAssessmentListByRecipientAndStatus = (
  carePlanUID?: string,
  careRecipientUID?: string,
  relatedEntity?: string,
  status?: string
) => {
  let url = "DataList/List";
  if (status === "Published") {
    url = "DataList/ListPublished";
  } else if (status === "Draft") {
    url = "DataList/ListDraft";
  } else if (status === "Current") {
    url = "DataList/Current";
  } else if (status === "History") {
    url = "DataList/List";
  }
  return new Promise<[]>((resolve, reject) => {
    if (isNotNullAndUndefined(relatedEntity)) {
      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      if (IsNotNullOrWhiteSpace(carePlanUID)) {
        let filter1: RAFCustomFilter = {};
        let filterVal1: string[] = [];
        filterVal1.push(carePlanUID);
        filter1.Operator = RAFCustomOperator.Equal;
        filter1.Value = filterVal1;
        filter1.Field = "care_planuid";
        relatedFilter.Rules.push(filter1);
      }

      if (IsNotNullOrWhiteSpace(careRecipientUID)) {
        let filter2: RAFCustomFilter = {};
        let filterVal2: string[] = [];
        filterVal2.push(careRecipientUID);
        filter2.Operator = RAFCustomOperator.Equal;
        filter2.Value = filterVal2;
        filter2.Field = "care_recipientuid";
        relatedFilter.Rules.push(filter2);
      }

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = relatedFilter;
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      // if (status === "Current") {
      //   let sortBy = {
      //     field: "CurrentStatus",
      //     order: "descending",
      //   };
      //   let sortQuery: string[] = [];
      //   sortQuery.push(
      //     sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
      //   );

      //   listServiceRequest.Sort = sortQuery;
      // }

      listServiceRequest.EntityName = relatedEntity;

      repositoryActions
        .postDataAndGetResponse(
          url,
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.Entity)
            ) {
              resolve(response.data.Entity);
            } else if (
              response.data &&
              response.data.ResultTable &&
              response.data.ResultTable.length > 0
            ) {
              resolve(response.data.ResultTable);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const ReviseAssessment = (objectId?: string, entityName?: string) => {
  return new Promise<string>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "Datalist/Revise",
        {
          EntityId: objectId,
          EntityName: entityName,
        },
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          resolve(response.data.EntityId);
        } else {
          showWarningToast("Sorry something went wrong !");
          resolve(null);
        }
      })
      .catch((error) => error);
  });
};
