import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import {
  DropDownButtonComponent,
  ItemModel,
  MenuEventArgs,
} from "@syncfusion/ej2-react-splitbuttons";
import DOMPurify from "dompurify";
import React, { PropsWithChildren, Reducer, useContext, useReducer, useRef } from "react";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFIconImage from "../../../RAFComponents/Navigation/RAFIconImage";
import RAFPanel from "../../../RAFComponents/Navigation/RAFPanel";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import { showSuccessToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  RafClearDialogContent,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender, { hasPermission } from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IsNotNullOrWhiteSpace,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import {
  RAFDataType,
  RAFUIType,
} from "../../../RAFComponents/models/Common/RAFDataType";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { CareAssessmentPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  ContentType,
  MomentFormats,
  RAFButtonConstant,
  RAFLayout,
  RAFRiskRegisterType,
} from "../../../constants/Common/Constants";
import { RAFBPCurrentStatus } from "../../../constants/Common/RMConstants";
import CareAuditList from "../AuditTimeLine/CareAuditDetails/CareAuditList";
import CareGoalList from "../CareGoal/CareGoalList";
import CareInterventionSupportList from "../CareInterventionSupport/CareInterventionSupportList";
import RiskRegisterList from "../RiskRegister/RiskRegisterList";
import ReviseCareAssessment from "./ReviseCareAssessment";
import UpdateCareAssessment from "./UpdateCareAssessment";

interface IProps {
  careAssessmentUID: string; //AssessmentUID
  careRecipientUID: string;
  careAssessmentRow: any;
  carePlanSectionEntity?: EntityRow;
  onSave: () => void;
  onDelete: () => void;
  IsCollapsed: boolean;
  showRelatedSections?: boolean;
  theme?: "White" | "default";
  entityName?: string;
}

interface IState {
  showUpdateCareAssessmentContent: boolean;
  showCreateAssessmentsContent: boolean;
  showHistoryAssessmentsContent: boolean;
  objectId?: string;
  isEditable?: boolean;
  showReviseCareAssessmentContent: boolean;
}

function CareAssessmentSection({
  careAssessmentRow,
  careAssessmentUID,
  ...props
}: PropsWithChildren<IProps>) {
  let riskRegisterListRef = useRef(null);
  let careGoalListRef = useRef(null);
  let careInterventionSupportListRef = useRef(null);
  let careAssessmentSectionDialogRef = useRef<DialogComponent>(null);

  const careAssessmentModule = CareEsioEntity.CareAssessment.EntityName;

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showUpdateCareAssessmentContent: false,
      showCreateAssessmentsContent: false,
      showHistoryAssessmentsContent: false,
      objectId: careAssessmentUID,
      isEditable: false,
      showReviseCareAssessmentContent: false,
    }
  );

  //revise CareAssessment_start
  const reviseCareAssessment = () => {
    let progressDiv = showProgress("body", true);
    repositoryActions
      .postDataAndGetResponse(
        "DataList/Revise",
        {
          EntityId: careAssessmentRow.UID,
          EntityName: isNotNullAndUndefined(careAssessmentRow.Assessment)
            ? careAssessmentRow.Assessment
            : CareEsioEntity.CareAssessment.EntityName,
        },
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          showSuccessToast("New version created successfully");
          if (props.onSave) {
            props.onSave();
          }
        }
      });
  };

  const showReviseCareAssessmentDialog = () => {
    setState({ showReviseCareAssessmentContent: true });
  };

  const reviseCareAssessmentContent = () => {
    if (state.showReviseCareAssessmentContent === true) {
      return (
        <ReviseCareAssessment
          //careAssessmentRow={careAssessmentRow}
          objectUID={state.objectId}
          onSave={refreshOnUpdate.bind(this)}
          onClose={closeReviseCareAssessmentDialog.bind(this)}
          isEditable={state.isEditable}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const closeReviseCareAssessmentDialog = () => {
    setState({ showReviseCareAssessmentContent: false });
  };

  //revise CareAssessment_end

  //update CareAssessment_start
  const editCareAssessment = (objectId?: string, isEditable?: boolean) => {
    setState({ showUpdateCareAssessmentContent: true, objectId, isEditable });
  };

  function updateCareAssessmentContent() {
    if (state.showUpdateCareAssessmentContent === true) {
      return (
        <UpdateCareAssessment
          //careAssessmentRow={careAssessmentRow}
          objectUID={state.objectId}
          onSave={refreshOnUpdate.bind(this)}
          onDelete={onDeleteChildItem.bind(this)}
          onClose={closeUpdateCareAssessmentDialog.bind(this)}
          isEditable={state.isEditable}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  }

  const closeUpdateCareAssessmentDialog = async () => {
    await RafClearDialogContent(careAssessmentSectionDialogRef);
    setState({
      showUpdateCareAssessmentContent: false,
      objectId: null,
      isEditable: false,
    });
  };

  const refreshOnUpdate = async () => {
    await RafClearDialogContent(careAssessmentSectionDialogRef);
    setState({
      showUpdateCareAssessmentContent: false,
      showReviseCareAssessmentContent: false,
      objectId: null,
      isEditable: false,
    });
    if (props.onSave) {
      props.onSave();
    }
  };

  const onDeleteChildItem = async () => {
    await RafClearDialogContent(careAssessmentSectionDialogRef);
    if (props.onDelete) {
      props.onDelete();
    } else {
      refreshOnUpdate();
    }
  };

  //update CareAssessment_end

  //History CareAssessment_start

  //update CareAssessment_start
  const timelineCareAssessment = () => {
    setState({ showHistoryAssessmentsContent: true });
  };

  function timelineCareAssessmentContent() {
    if (state.showHistoryAssessmentsContent === true) {
      return <CareAuditList relatedToUID={careAssessmentRow.UID} />;
    } else {
      return <div></div>;
    }
  }

  const closeHistoryCareAssessmentDialog = async () => {
    await RafClearDialogContent(careAssessmentSectionDialogRef);
    setState({ showHistoryAssessmentsContent: false });
  };

  //History CareAssessment_end
  const carePlanSectionCustomButton = () => {
    return (
      <div className="row g-2 flex-nowrap" id="divHeaderButtons">
        <div className="col-auto d-none d-md-block">
          <ButtonComponent
            type="button"
            className="primary-custom-button"
            onClick={() => editCareAssessment(careAssessmentUID, true)}
            content={RAFButtonConstant.Edit.DisplayName}
            id={`btn_${RAFButtonConstant.Edit.Id}`}
            iconCss={RAFButtonConstant.Edit.IconCss}
          />
        </div>
        <div className="col-auto d-md-none">
          <ButtonComponent
            type="button"
            className="primary-custom-button"
            onClick={() => editCareAssessment(careAssessmentUID, true)}
            // content={RAFBtnIdString.Edit}
            id={`btn_${RAFButtonConstant.Edit.Id}`}
            iconCss={RAFButtonConstant.Edit.IconCss}
          // cssClass="link-button custom-button-sm"
          />
        </div>
      </div>
    );
  };

  const onActionMenuClicked = (args: MenuEventArgs) => {
    if (args.item.id === RAFButtonConstant.Timeline.Id) {
      timelineCareAssessment();
    }
    if (args.item.id === RAFButtonConstant.Revise.Id) {
      if (hasPermission(permissionValue, CareAssessmentPermissionConstants.CareAssessmentManage)) {
        reviseCareAssessment();
      } else {
        showSuccessToast("You do not have permission to revise care assessment");
      }
      //showReviseCareAssessmentDialog();
    }
  };

  const carePlanSectionCustomTitle = () => {
    const iconName = isNotNullAndUndefined(careAssessmentRow.Icon)
      ? careAssessmentRow.Icon
      : null;
    return (
      <div className="d-flex align-items-center">
        <RAFIconImage
          iconCssClass={"far fa-hand-holding-seedling"}
          moduleavatar="border-avatar avatar-text avatar-text-square"
          iconSize="44"
          fontSize="22"
        />
        <div className="ps-2 w-100">
          <div className="d-flex align-items-center">
            <span className="cardTitle ecllipseFirstLine word-break-all">
              {isNotNullAndUndefined(careAssessmentRow.sub_category)
                ? careAssessmentRow.sub_category
                : "Not set"}
            </span>
          </div>
          <div className="detailsLeftCard-second-title row-d-inline-block h-auto">
            <div className="row gx-2 flex-nowrap align-items-center">
              {isNotNullAndUndefined(careAssessmentRow.CurrentStatus) && (
                <div className="col-auto detailsLeftCard-second-title-col">
                  <div className="detailsLeftCard-second-title-item">
                    <RAFDetailFieldCustom
                      value={careAssessmentRow["assessment_trigger"]}
                      title="Assessment Trigger"
                      field="assessment_trigger"
                      moduleName={CareEsioEntity.CareAssessment.EntityName}
                      isColorOption
                      showLabel={false}
                    />
                  </div>
                </div>
              )}
              <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                <div className="detailsLeftCard-second-title-item">
                  <span>{`v${careAssessmentRow.version_number}.0`}</span>
                  {/* <div
                    className="ecllipseFirstLine noRowclick"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <RAFRecordInfo
                      createdBy={careAssessmentRow.CreatedBy}
                      createdDate={careAssessmentRow.CreatedDate}
                      modifiedDate={careAssessmentRow.ModifiedDate}
                      modifiedBy={careAssessmentRow.ModifiedBy}
                      lastActivityDate={careAssessmentRow.LastActivityDate}
                      hideClockIcon
                    ></RAFRecordInfo>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const customButton = (iconBtn?: boolean) => {
    return (
      <div className="d-flex">
        {careAssessmentRow.current_status === RAFBPCurrentStatus.Published &&
          careAssessmentRow.is_active === true && (
            <div className="d-flex">
              {iconBtn ? (
                <RAFButtonComponent
                  action={RAFButtonConstant.Details}
                  className="custom-button-sm content_state_warning_base border-0 e-round me-2"
                  onClick={() => editCareAssessment(careAssessmentUID, false)}
                  iconBtn
                ></RAFButtonComponent>
              ) : (
                <>
                  <RAFButtonComponent
                    action={RAFButtonConstant.Details}
                    showIcon={false}
                    className="primary-custom-button custom-button-sm d-none d-md-block me-2"
                    onClick={() => editCareAssessment(careAssessmentUID, false)}
                  ></RAFButtonComponent>
                  <RAFButtonComponent
                    action={RAFButtonConstant.Details}
                    className="primary-custom-button custom-button-sm d-md-none me-2"
                    iconBtn
                    onClick={() => editCareAssessment(careAssessmentUID, false)}
                  ></RAFButtonComponent>
                </>
              )}
              <DropDownButtonComponent
                items={[
                  {
                    id: RAFButtonConstant.Revise.Id,
                    text: RAFButtonConstant.Revise.DisplayName,
                    iconCss: RAFButtonConstant.Revise.IconCss,
                  },
                  {
                    id: RAFButtonConstant.Timeline.Id,
                    text: RAFButtonConstant.Timeline.DisplayName,
                    iconCss: RAFButtonConstant.Timeline.IconCss,
                  },
                ]}
                iconCss={RAFButtonConstant.More.IconCss}
                className="custom-button-sm border-0 e-caret-hide e-round"
                select={onActionMenuClicked.bind(this)}
              ></DropDownButtonComponent>
            </div>
          )}

        {careAssessmentRow.current_status === RAFBPCurrentStatus.Draft && (
          <div className="d-flex">
            <RAFPermissionRender
              permissionName={
                CareAssessmentPermissionConstants.CareAssessmentManage
              }
            >
              {iconBtn ? (
                <RAFButtonComponent
                  action={RAFButtonConstant.Edit}
                  className="custom-button-sm content_state_warning_base border-0 e-round me-2"
                  onClick={() => editCareAssessment(careAssessmentUID, true)}
                  iconBtn
                ></RAFButtonComponent>
              ) : (
                <>
                  <ButtonComponent
                    type="button"
                    className="primary-custom-button custom-button-sm d-none d-md-block me-2"
                    onClick={() => editCareAssessment(careAssessmentUID, true)}
                    content={RAFButtonConstant.Edit.DisplayName}
                    id={`btn_${RAFButtonConstant.Edit.Id}`}
                    iconCss={RAFButtonConstant.Edit.IconCss}
                  />
                  <ButtonComponent
                    type="button"
                    className="primary-custom-button custom-button-sm d-md-none me-2"
                    onClick={() => editCareAssessment(careAssessmentUID, true)}
                    //content={RAFButtonConstant.Edit.DisplayName}
                    id={`btn_${RAFButtonConstant.Edit.Id}`}
                    iconCss={RAFButtonConstant.Edit.IconCss}
                  />
                </>
              )}
            </RAFPermissionRender>
            <DropDownButtonComponent
              items={[
                {
                  id: RAFButtonConstant.Timeline.Id,
                  text: RAFButtonConstant.Timeline.DisplayName,
                  iconCss: RAFButtonConstant.Timeline.IconCss,
                },
              ]}
              iconCss={RAFButtonConstant.More.IconCss}
              className="custom-button-sm e-outline e-caret-hide e-round border-0"
              select={onActionMenuClicked.bind(this)}
            ></DropDownButtonComponent>
          </div>
        )}
      </div>
    );
  };

  const getRiskRegisterContent = () => {
    return (
      <RiskRegisterList
        ref={riskRegisterListRef}
        careAssessmentUID={careAssessmentUID}
        careAssessmentRow={careAssessmentRow}
        careRecipientUID={null}
        showEmptyState={true}
        allowEdit={
          careAssessmentRow.current_status === RAFBPCurrentStatus.Draft
        }
        showAddBtn={
          careAssessmentRow.current_status === RAFBPCurrentStatus.Draft
        }
        uiStyle={"CollapsePanelWithOutCardStyle"}
        riskType={RAFRiskRegisterType.Risk}
        paddingClass="p-0"
        theme={props.theme === "White" ? "default" : "White"}
      />
    );
  };

  const getCareGoalContent = () => {
    return (
      <CareGoalList
        ref={careGoalListRef}
        careAssessmentUID={careAssessmentUID}
        careAssessmentRow={careAssessmentRow}
        careRecipientUID={null}
        careRecipientName={null}
        showEmptyState={false}
        showAddBtn={false}
      />
    );
  };

  const getCareInterventionSupportContent = () => {
    return (
      <CareInterventionSupportList
        ref={careInterventionSupportListRef}
        careAssessmentUID={careAssessmentUID}
        careAssessmentRow={careAssessmentRow}
        careRecipientUID={null}
        showEmptyState={true}
        allowEdit={
          careAssessmentRow.current_status === RAFBPCurrentStatus.Draft
        }
        showAddBtn={
          careAssessmentRow.current_status === RAFBPCurrentStatus.Draft
        }
        uiStyle={"CollapsePanelWithOutCardStyle"}
        theme={props.theme === "White" ? "default" : "White"}
      />
    );
  };

  const carePlanSectionCustomFooterContent = () => {
    let moreMenuOptions: ItemModel[] = [
      { id: "addrisk", text: "Add Risk" },
      //{ id: 'addgoal', text: 'Add Goal' },
      { id: "addintervention", text: "Add Support" },
    ];

    return (
      <div className="px-3 py-2 border-top">
        <div className="row g-2 justify-content-end">
          <div className="col-auto d-lg-block d-none">
            <RAFButtonComponent
              className="custom-button-sm primary-custom-button"
              // className="custom-button-md"
              action={RAFButtonConstant.Add}
              btnContent={"Add Risk"}
              onClick={() => onClickAddRiskRegister()}
            />
          </div>
          {/* <div className="col-auto d-lg-block d-none">
            <RAFButtonComponent
              className="custom-button-sm primary-custom-button"
              // className="custom-button-md"
              action={RAFButtonConstant.Add}
              btnContent={"Add Goal"}
              onClick={() => onClickAddCareGoal()}
            />
          </div> */}
          <div className="col-auto d-xl-block d-none">
            <RAFButtonComponent
              className="custom-button-sm primary-custom-button"
              // className="custom-button-md"
              action={RAFButtonConstant.Add}
              btnContent={"Add Support"}
              onClick={() => onClickAddInterventionSupport()}
            />
          </div>
          <div className="col-auto d-xl-none">
            <DropDownButtonComponent
              items={moreMenuOptions}
              iconCss="fas fa-ellipsis-vertical"
              className="custom-button-sm primary-custom-button e-caret-hide"
              select={onMoreMenuClicked}
            ></DropDownButtonComponent>
          </div>
        </div>
      </div>
    );
  };

  const onMoreMenuClicked = (args: MenuEventArgs) => {
    if (args.item.id === "addrisk") {
      onClickAddRiskRegister();
    }
    if (args.item.id === "addgoal") {
      onClickAddCareGoal();
    }
    if (args.item.id === "addintervention") {
      onClickAddInterventionSupport();
    }
  };

  const onClickAddCareGoal = () => {
    if (
      isNotNullAndUndefined(careGoalListRef) &&
      isNotNullAndUndefined(careGoalListRef.current)
    ) {
      careGoalListRef.current.toggleCreateDlg();
    }
  };

  const onClickAddInterventionSupport = () => {
    if (
      isNotNullAndUndefined(careInterventionSupportListRef) &&
      isNotNullAndUndefined(careInterventionSupportListRef.current)
    ) {
      careInterventionSupportListRef.current.toggleCreateDlg();
    }
  };

  const customContent = () => {
    return (
      <div className="row gx-0 gy-0 gy-md-0">
        <div className="col-12">
          <div className="w-100 bg-white px-3 py-2">
            <div className="row gx-0 gy-2">
              <div className="col-12">
                <div className="row gx-4 gy-3">
                  {isNotNullAndUndefined(careAssessmentRow["review_date"]) ? (
                    <div className="col-sm-6 col-xl-3 col-12">
                      <div className="details-label withHeight">
                        Review Date
                      </div>
                      <div className="e-badge e-badge-outline">
                        <RAFDetailFieldCustom
                          value={careAssessmentRow["review_date"]}
                          title="Review"
                          field="review_date"
                          showLabel={false}
                          fieldFormat={{
                            type: RAFDataType.Date,
                            format: MomentFormats.DATE,
                          }}
                        />
                      </div>
                      {/* <div className="d-none d-md-block">
                      <div>Review:</div>
                      <div className="ms-1 e-badge e-badge-outline">
                        {getFormatedDate(
                          careAssessmentRow["ReviewDate"],
                          MomentFormats.DATE
                        )}
                      </div>
                    </div>
                    <div className="d-md-none">
                      {`${getFormatedDate(
                        careAssessmentRow["ReviewDate"],
                        MomentFormats.DATE
                      )}`}
                    </div> */}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* {isNotNullAndUndefined(
                    careAssessmentRow.VersionNumber
                  ) && (
                      <div className="col-sm-6 col-lg-3 col-12">
                        <div className="details-label withHeight">
                          Version
                        </div>
                        <RAFDetailFieldCustom
                          value={`${careAssessmentRow["VersionNumber"]}.0`}
                          title="Versions"
                          field="VersionNumber"
                          showLabel={false}
                        />
                        <div className="detailsLeftCard-second-title-item remove__icon__color d-none">
                          <div className="d-none d-md-inline-block">
                            <div>Version:</div>
                            <div className="ms-1">
                              {careAssessmentRow.VersionNumber}
                            </div>
                          </div>
                          {/* <RAFDetailFieldCustom
                      value={
                        isNotNullAndUndefined(careAssessmentRow.VersionNumber)
                          ? `v(${careAssessmentRow.VersionNumber})`
                          : "Not set"
                      }
                      //   displayValue={careInterventionSupportRow.Frequency}
                      //title="Independence Level"
                      moduleName={CareEsioEntity.CareAssessment.EntityName}
                      field="VersionNumber"
                      showLabel={false}
                    /> */}
                  {/*}   </div>
                      </div>
                    )} */}
                  {isNotNullAndUndefined(
                    careAssessmentRow.independence_level
                  ) && (
                      <div className="col-sm-6 col-xl-3 col-12">
                        <div className="details-label withHeight">
                          Support Level
                        </div>
                        <div
                          className="e-badge e-badge-outline"
                          style={{ fontSize: "1em" }}
                        >
                          <RAFDetailFieldCustom
                            value={
                              isNotNullAndUndefined(
                                careAssessmentRow.independence_level
                              )
                                ? careAssessmentRow.independence_level
                                : "Not set"
                            }
                            //   displayValue={careInterventionSupportRow.Frequency}
                            title="Support Level"
                            moduleName={CareEsioEntity.CareAssessment.EntityName}
                            field="independence_level"
                            uiType={RAFUIType.SmileyToggle}
                            showLabel={false}
                          />
                        </div>
                      </div>
                    )}

                  {isNotNullAndUndefined(
                    careAssessmentRow["current_status"]
                  ) ? (
                    <div className="col-sm-6 col-xl-3 col-12">
                      <div className="details-label withHeight">Status</div>
                      <RAFDetailFieldCustom
                        value={
                          isNotNullAndUndefined(
                            careAssessmentRow.current_status
                          )
                            ? careAssessmentRow.current_status
                            : "Not set"
                        }
                        title="Status"
                        field="current_status"
                        moduleName={CareEsioEntity.CareAssessment.EntityName}
                        isColorOption
                        showLabel={false}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-sm-6 col-xl-3 col-12">
                    <div className="details-label withHeight">Last updated</div>
                    <div className="d-flex">
                      <RAFRecordInfo
                        createdBy={careAssessmentRow.CreatedBy}
                        createdDate={careAssessmentRow.CreatedDate}
                        modifiedDate={careAssessmentRow.ModifiedDate}
                        modifiedBy={careAssessmentRow.ModifiedBy}
                        lastActivityDate={careAssessmentRow.LastActivityDate}
                        hideClockIcon
                      ></RAFRecordInfo>
                    </div>
                  </div>
                  {/* {isNotNullAndUndefined(
                            careAssessmentRow.CurrentStatus
                          ) && (
                              <div className="col-sm col-12 mb-2">
                                <div className="details-label withHeight">Status</div>
                                <div
                                  className="e-badge e-badge-outline"
                                  style={{ fontSize: "1em" }}
                                >
                                  <RAFDetailFieldCustom
                                    value={
                                      isNotNullAndUndefined(
                                        careAssessmentRow.CurrentStatus
                                      )
                                        ? careAssessmentRow.CurrentStatus
                                        : "Not set"
                                    }
                                    title="CurrentStatus"
                                    //moduleName={CareEsioEntity.CareAssessment.EntityName}
                                    field="CurrentStatus"
                                    showLabel={false}
                                  />
                                </div>
                              </div>
                            )} */}
                  {/* <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                      <div className="detailsLeftCard-second-title-item">
                        <div
                          className="ecllipseFirstLine noRowclick"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <RAFRecordInfo
                            createdBy={careAssessmentRow.CreatedBy}
                            createdDate={careAssessmentRow.CreatedDate}
                            modifiedDate={careAssessmentRow.ModifiedDate}
                            modifiedBy={careAssessmentRow.ModifiedBy}
                            lastActivityDate={careAssessmentRow.LastActivityDate}
                            hideClockIcon
                          ></RAFRecordInfo>
                        </div>
                      </div>
                    </div> */}
                </div>
              </div>
              {!props.showRelatedSections &&
                isNotNullAndUndefined(careAssessmentRow.assessment_notes) && (
                  <div className="col-12">
                    <div className="secondary-header-text-dark p-2 bg-rgba-white">
                      {/* {careAssessmentRow.AssessmentNotes} */}
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            careAssessmentRow !== null
                              ? DOMPurify.sanitize(
                                careAssessmentRow.assessment_notes
                              )
                              : undefined,
                        }}
                      ></span>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        {isNotNullAndUndefined(careAssessmentRow.current_status) &&
          careAssessmentRow.current_status === RAFBPCurrentStatus.Published && (
            <div className="col-12">{getCareInterventionSupportContent()}</div>
          )}
      </div>
    );
  };

  const customNewContent = () => {
    return (
      <RAFPanel layout={RAFLayout.OneColumnLayout} className="gx-0 gy-3">
        <div>
          <div>
            <span className="subtitle_1">
              {/* {isNotNullAndUndefined(careAssessmentRow.sub_category)
                ? careAssessmentRow.sub_category
                : isNotNullAndUndefined(careAssessmentRow.category)
                ? careAssessmentRow.category
                : "Category Notset"} */}
              {isNotNullAndUndefined(careAssessmentRow.title)
                ? careAssessmentRow.title
                : "Not set"}
            </span>
          </div>
          {IsNotNullOrWhiteSpace(careAssessmentRow.assessment_notes) ? (
            <div className="mt-1">
              <span
                className="description-text body_2"
                dangerouslySetInnerHTML={{
                  __html:
                    careAssessmentRow.assessment_notes !== null
                      ? DOMPurify.sanitize(careAssessmentRow.assessment_notes)
                      : undefined,
                }}
              ></span>
            </div>
          ) : (
            ""
          )}

          <div className="detailsLeftCard-second-title row-d-inline-block h-auto mt-2">
            <div className="row gx-2 flex-nowrap align-items-center">
              {isNotNullAndUndefined(careAssessmentRow.current_status) && (
                <div className="col-auto detailsLeftCard-second-title-col">
                  <div className="detailsLeftCard-second-title-item">
                    <RAFDetailFieldCustom
                      value={careAssessmentRow["assessment_trigger"]}
                      title="Assessment Trigger"
                      field="assessment_trigger"
                      moduleName={CareEsioEntity.CareAssessment.EntityName}
                      isColorOption
                      showLabel={false}
                      mode="textOnly"
                      valueClassName="body_3"
                    />
                  </div>
                </div>
              )}
              <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                <div className="detailsLeftCard-second-title-item">
                  <span className="body_3 content_neutral_base medium italic">{`v${careAssessmentRow.version_number}.0`}</span>
                </div>
              </div>
              <div className="col-auto detailsLeftCard-second-title-col ecllipseFirstLine">
                <div className="detailsLeftCard-second-title-item">
                  <div
                    className="ecllipseFirstLine noRowclick"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <RAFRecordInfo
                      createdBy={careAssessmentRow.CreatedBy}
                      createdDate={careAssessmentRow.CreatedDate}
                      modifiedDate={careAssessmentRow.ModifiedDate}
                      modifiedBy={careAssessmentRow.ModifiedBy}
                      lastActivityDate={careAssessmentRow.LastActivityDate}
                      spanContent={"Updated "}
                      dropdownBtnClassName="custom-button-sm"
                      spanContentClassName="body_3 content_neutral_base medium italic text-decoration-underline"
                      hideClockIcon
                    ></RAFRecordInfo>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="custom__card__separator custom__card__separator__light"></div>
        {getCareInterventionSupportContent()}
        {getRiskRegisterContent()}
      </RAFPanel>
    );
  };

  const onClickAddRiskRegister = () => {
    if (
      isNotNullAndUndefined(riskRegisterListRef) &&
      isNotNullAndUndefined(riskRegisterListRef.current)
    ) {
      riskRegisterListRef.current.toggleCreateDlg();
    }
  };

  const careAssessmentCustomFooterContent = (careAssessmentRow) => {
    return (
      <div
        className={`${props.theme === "White" ? "" : "bg-white "
          }care_recipient_care_plan_item_footer justify-content-between`}
      >
        <div>
          <RAFDetailFieldCustom
            value={careAssessmentRow["current_status"]}
            title="CurrentStatus"
            field="current_status"
            moduleName={CareEsioEntity.CareAssessment.EntityName}
            isColorOption
            showLabel={false}
            mode="outlineView"
            valueClassName="raf_badge"
          />
        </div>
        <div className="">{customButton(true)}</div>
      </div>
    );
  };

  if (isNotNullAndUndefined(careAssessmentRow)) {
    return (
      <div
        className="col-12"
        style={{ borderBottom: "1px solid rgba(255,255,255,0.5)" }}
        key={careAssessmentRow.UID}
      >
        {props.showRelatedSections !== true ? (
          <CustomCardWidget
            cardClassName={`${props.theme === "White" ? "White " : ""
              }care_recipient_care_plan_item`}
            footerTemplate={careAssessmentCustomFooterContent(
              careAssessmentRow
            )}
          >
            {customNewContent()}
          </CustomCardWidget>
        ) : (
          // <RAFCollapse
          //   customButton={customButton()}
          //   customTitle={carePlanSectionCustomTitle()}
          //   removeContentCardPadding
          //   showCustomButtonOnHover
          //   IsCollapsed={props.IsCollapsed}
          // >
          //   {customContent()}
          // </RAFCollapse>
          <CustomCardWidget
            removeContentPadding
            cardClassName="overflow-hidden"
          >
            <RAFCollapse
              //title={careAssessmentRow.Category}
              headerRowClassName="with-height bg-grey-2"
              contentCardClassName="relatedListOuterDiv"
              // customButton={carePlanSectionCustomButton()}
              customTitle={carePlanSectionCustomTitle()}
              removeContentCardPadding
              showCustomButtonOnHover
              // {...(props.showRelatedSections
              //   ? {
              //     customFooterContent: carePlanSectionCustomFooterContent()
              //   }
              //   : {})}
              //customFooterContent={carePlanSectionCustomFooterContent()}
              IsCollapsed={props.IsCollapsed}
            >
              {customContent()}
              {false && (
                <div className="p-2 p-md-3">
                  <div className="row gy-2 gx-0">
                    {false && (
                      <div className="col-12">
                        {isNotNullAndUndefined(
                          careAssessmentRow.AssessmentNotes
                        ) && (
                            <div className="header-text-sm-light">
                              {careAssessmentRow.AssessmentNotes}
                            </div>
                          )}
                        {false && (
                          <div className="row gy-3 gx-0">
                            <RAFDetailFieldCustom
                              value={careAssessmentRow.AssessmentNotes}
                              title={"Current Assessment"}
                              colClassName="col-md-6"
                              rowClassName="gy-2"
                            // labelDivClassName="col-3"
                            // valueDivClassName="col-9"
                            />
                            <RAFDetailFieldCustom
                              value={careAssessmentRow.CurrentDate}
                              title={"Assessment Date"}
                              fieldFormat={{
                                type: RAFDataType.Date,
                                format: "DD/MM/YYYY",
                              }}
                              colClassName="col-md-6"
                              rowClassName="gy-2"
                            // labelDivClassName="col-3"
                            // valueDivClassName="col-9"
                            />
                            <RAFDetailFieldCustom
                              value={careAssessmentRow.NextDate}
                              title={"Next Assessment Date"}
                              fieldFormat={{
                                type: RAFDataType.Date,
                                format: "DD/MM/YYYY",
                              }}
                              colClassName="col-md-6"
                              rowClassName="gy-2"
                            />
                            <RAFDetailFieldCustom
                              value={careAssessmentRow.AssessmentType}
                              title={"Type"}
                              colClassName="col-md-6"
                              rowClassName="gy-2"
                            // labelDivClassName="col-3"
                            // valueDivClassName="col-9"
                            />
                            <RAFDetailFieldCustom
                              value={careAssessmentRow.AssessmentTrigger}
                              title={"Trigger"}
                              colClassName="col-md-6"
                              rowClassName="gy-2"
                            // labelDivClassName="col-3"
                            // valueDivClassName="col-9"
                            />
                            <RAFDetailFieldCustom
                              value={careAssessmentRow.Responsibilities}
                              title={"Responsibilities"}
                              colClassName="col-md-6"
                              rowClassName="gy-2"
                            // labelDivClassName="col-3"
                            // valueDivClassName="col-9"
                            />

                            {/* {allFieldsCurrentItem.map((field, index) => {
            return (
                <RAFDetailFieldCustom
                    key={index}
                    value={careAssessmentRow[field]}
                    title={field}
                    colClassName='col-12 py-2'
                    labelDivClassName="col-3"
                    valueDivClassName="col-9"
                />
            );
        })} */}
                          </div>
                        )}
                      </div>
                    )}
                    {props.showRelatedSections === true && (
                      <div className="col-12">
                        <div className="mb-2">{getRiskRegisterContent()}</div>
                        {/* <div className="mb-2">{getCareGoalContent()}</div> */}
                        <div>{getCareInterventionSupportContent()}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {props.showRelatedSections &&
                carePlanSectionCustomFooterContent()}
            </RAFCollapse>
          </CustomCardWidget>
        )}

        {state.showUpdateCareAssessmentContent && (
          <DialogComponent
            header={
              careAssessmentRow.CurrentStatus === RAFBPCurrentStatus.Published
                ? state.isEditable === false
                  ? `Section Details`
                  : `Revise Section`
                : `Update Section`
            }
            showCloseIcon
            visible={state.showUpdateCareAssessmentContent}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_update_${careAssessmentModule}`}
            content={updateCareAssessmentContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeUpdateCareAssessmentDialog.bind(this)}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
            ref={careAssessmentSectionDialogRef}
          ></DialogComponent>
        )}
        {state.showReviseCareAssessmentContent && (
          <DialogComponent
            header={`Revise ${CareEsioEntity.CareAssessment.DisplayName}`}
            showCloseIcon
            visible={state.showReviseCareAssessmentContent}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_update_${careAssessmentModule}`}
            content={reviseCareAssessmentContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeReviseCareAssessmentDialog.bind(this)}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
            ref={careAssessmentSectionDialogRef}
          ></DialogComponent>
        )}
        {state.showHistoryAssessmentsContent && (
          <DialogComponent
            header={`Timeline`}
            showCloseIcon
            visible={state.showHistoryAssessmentsContent}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_timeline_${careAssessmentModule}`}
            content={timelineCareAssessmentContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeHistoryCareAssessmentDialog.bind(this)}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
            ref={careAssessmentSectionDialogRef}
          ></DialogComponent>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(CareAssessmentSection);
