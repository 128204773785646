import {
  MenuEventArgs,
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import * as R from "ramda";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapseWithPlusIcon from "../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  getAllAttributes,
  getFields,
  getRelatedRecords,
  getUnique,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  subscribeRAFEvent,
  unsubscribeRAFEvent,
} from "../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  ConvertSystemName,
  ConvertToElementID,
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { getEntityByObjectName } from "../../../RAFMaster/helpers/RMutils";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareAssessmentPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { RAFButtonConstant } from "../../../constants/Common/Constants";
import { RAFBPCurrentStatus } from "../../../constants/Common/RMConstants";
import { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import { getAssessmentListByCarePlanAndRecipientUID } from "./CareAssessmentHelper";
import CareAssessmentListContent from "./CareAssessmentListContent";
import CareAssessmentSection from "./CareAssessmentSection";
import CreateCareAssessment from "./CreateCareAssessment";

interface IProps {
  careRecipientUID?: string;
  activeCarePlan?: any;
  showHeaderTitle?: boolean;
  uiStyle?: "CardStyle" | "DetailsStyle";
  showAddBtn?: boolean;
  careRecipientRow?: any;
  theme?: "White" | "default";
  entityName?: string;
}

interface IState {
  isLoading?: boolean;
  careAssessmentSectionUID?: string;
  careAssessments?: any;
  showCreateCareAssessmentSectionContent?: boolean;
  isCreatedCareAssessment?: boolean;
  showRelatedSections?: boolean;
  selectedTabItem?: any;
  selectedStatus?: string;
}

//function CareAssessmentList({ ...props }: PropsWithChildren<IProps>) {
function CareAssessmentList({
  showHeaderTitle = false,
  uiStyle = "CardStyle",
  ...props
}: PropsWithChildren<IProps>) {
  const rafObject = React.useContext(RAFObjectContext);
  const careAssessmentModule = CareEsioEntity.Assessment.EntityName;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      careAssessmentSectionUID: null,
      careAssessments: null,
      showRelatedSections: false,
      showCreateCareAssessmentSectionContent: false,
      isCreatedCareAssessment: false,
      selectedTabItem: {
        text: RAFBPCurrentStatus.Published,
        value: RAFBPCurrentStatus.Published,
      },
      selectedStatus: "Current",
    }
  );

  useEffect(() => {
    refresh();
  }, [props.careRecipientUID, props.activeCarePlan]);

  useEffect(() => {
    const relatedModule = `${CareEsioEntity.CarePlan.EntityName}_${careAssessmentModule}`;

    subscribeRAFEvent(
      `${RAFEventName.GridLoad}_${relatedModule}_${props.careRecipientUID}`,
      reLoadListContent
    );

    return () => {
      unsubscribeRAFEvent(
        `${RAFEventName.GridLoad}_${relatedModule}_${props.careRecipientUID}`,
        reLoadListContent
      );
    };
  });

  const reLoadListContent = () => {
    refresh();
  };

  const refresh = async () => {
    setState({
      isLoading: true,
      isCreatedCareAssessment: false,
      showCreateCareAssessmentSectionContent: false,
    });

    const activeCarePlanUID = isNotNullAndUndefined(props.activeCarePlan)
      ? props.activeCarePlan.UID
      : null;

    const [
      careInterventionSupportEntity, // Result of getEntityByObjectName for CareInterventionSupport
      riskRegisterEntity, // Result of getEntityByObjectName for RiskRegister
      careInterventionSupportAttributes, // Result of getAllAttributes for CareInterventionSupport
      careInterventionSupportFields, // Result of getFields for CareInterventionSupport
      riskRegisterAttributes, // Result of getAllAttributes for RiskRegister
      riskRegisterFields, // Result of getFields for RiskRegister
      careAssessmentAttributes, // Result of getAllAttributes for CareAssessment
      careAssessmentFields, // Result of getFields for CareAssessment
    ] = await Promise.all([
      getEntityByObjectName({
        ObjectName: ConvertSystemName(
          CareEsioEntity.CareInterventionSupport.EntityName
        ),
      }),
      getEntityByObjectName({
        ObjectName: ConvertSystemName(CareEsioEntity.RiskRegister.EntityName),
      }),
      getAllAttributes(
        ConvertSystemName(CareEsioEntity.CareInterventionSupport.EntityName)
      ),
      getFields(
        ConvertSystemName(CareEsioEntity.CareInterventionSupport.EntityName)
      ),
      getAllAttributes(
        ConvertSystemName(CareEsioEntity.RiskRegister.EntityName)
      ),
      getFields(ConvertSystemName(CareEsioEntity.RiskRegister.EntityName)),
      getAllAttributes(
        ConvertSystemName(CareEsioEntity.CareAssessment.EntityName)
      ),
      getFields(ConvertSystemName(CareEsioEntity.CareAssessment.EntityName)),
    ]);
    // Note: The comment about not removing the line regarding `careInterventionSupportEntity` is addressed by ensuring
    // that the variable is correctly assigned and used below this line as intended.

    if (isNotNullAndUndefined(activeCarePlanUID)) {
      const careAssessments = await getAssessmentListByCarePlanAndRecipientUID(
        activeCarePlanUID,
        props.careRecipientUID,
        CareEsioEntity.CareAssessment.EntityName
      );
      setState({
        isLoading: false,
        careAssessments,
        showRelatedSections: true,
      });
    } else if (isNotNullAndUndefined(props.careRecipientUID)) {
      let sortBy = {
        field: "sub_category",
        order: "ascending",
      };
      let sortQuery: string[] = [];
      sortQuery.push(
        sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
      );

      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter1: RAFCustomFilter = {};
      let filterVal1: string[] = [];
      filterVal1.push("true");
      filter1.Operator = RAFCustomOperator.Equal;
      filter1.Value = filterVal1;
      filter1.Field = "is_active";
      relatedFilter.Rules.push(filter1);

      const careAssessments = await getRelatedRecords(
        CareEsioEntity.CareAssessment.EntityName,
        null,
        "care_recipientuid",
        props.careRecipientUID,
        null,
        CareEsioEntity.CareAssessment.EntityName,
        sortQuery,
        null,
        relatedFilter
      );

      setState({
        isLoading: false,
        careAssessments,
        showRelatedSections: false,
      });
    }
  };

  const careAssessmentSectionListContent1 = () => {
    const { careAssessments, showRelatedSections } = state;
    isNotNullAndUndefined(careAssessments) &&
      careAssessments.sort((x, y) => (x.category > y.category ? 1 : -1));
    let careAssessmentCategory = getUnique(careAssessments, "categoryuid");
    const careAssessmentsClone = R.clone(state.careAssessments);
    isNotNullAndUndefined(careAssessmentsClone) &&
      careAssessmentsClone.sort((x, y) =>
        x.sub_category > y.sub_category ? 1 : -1
      );
    return state.isLoading === false ? (
      <div>
        {isNotEmptyArray(careAssessmentCategory) ? (
          careAssessmentCategory.map((item) => {
            return (
              <div key={item.UID} className="col-12 mb-3">
                {showRelatedSections && (
                  <div className="second_header">
                    <span className="cardTitle ecllipseFirstLine word-break-all">
                      {item.category}
                    </span>
                  </div>
                )}
                <div className={showRelatedSections ? "p-3" : "row g-0 gy-3"}>
                  {careAssessmentsClone.map((subCategory) => {
                    if (item.categoryuid === subCategory.categoryuid) {
                      return (
                        <CareAssessmentSection
                          key={subCategory.UID}
                          careAssessmentUID={subCategory.UID}
                          careAssessmentRow={subCategory}
                          careRecipientUID={props.careRecipientUID}
                          onSave={refresh}
                          onDelete={refresh}
                          showRelatedSections={showRelatedSections}
                          IsCollapsed={true}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <div className="my-3">
            <div className="row gy-3">
              <div className="col-12">
                <RAFEmptyState
                  //title={`No active ${CareEsioEntity.Assessment.DisplayName}`}
                  title={`No active support need`}
                  body={`Please click on add button to add support need.`}
                  displayStyle="TextOnly"
                />
              </div>
              {/* <div className="col-12">
                <RAFPermissionRender
                  permissionName={
                    CareAssessmentPermissionConstants.CareAssessmentManage
                  }
                >
                  <RAFButtonComponent
                    type="button"
                    action={RAFButtonConstant.Add}
                    className="e-outline w-100 btn-dashed btn-roundedCorner"
                    isPrimary
                    onClick={() => onClickCareAssessmentSection()}
                  />
                </RAFPermissionRender>
              </div> */}
            </div>
          </div>
        )}
      </div>
    ) : (
      <div className="container-fluid px-0">
        <ACLoadingPanel loadingText="Preparing Data..." />
      </div>
    );
  };

  const careAssessmentSectionListContent = () => {
    const { showRelatedSections } = state;
    const careAssessments = R.clone(state.careAssessments);

    const uniqueCategories: string[] = isNotEmptyArray(careAssessments)
      ? Array.from(new Set(careAssessments.map((item) => item.category)))
      : [];

    return state.isLoading === false ? (
      <div className="p-3">
        <div className="row gx-0 gy-3">
          {isNotEmptyArray(uniqueCategories) ? (
            uniqueCategories.map((item) => {
              return (
                <div key={ConvertToElementID(item)} className="col-12">
                  {showRelatedSections && (
                    <div className="second_header">
                      <span className="cardTitle ecllipseFirstLine word-break-all">
                        {item}
                      </span>
                    </div>
                  )}
                  <div className={showRelatedSections ? "p-3" : "row g-0 gy-3"}>
                    {careAssessments.map((subCategory) => {
                      if (
                        isNotNullAndUndefined(item) &&
                        item === subCategory.category
                      ) {
                        return (
                          <CareAssessmentSection
                            key={subCategory.UID}
                            careAssessmentUID={subCategory.UID}
                            careAssessmentRow={subCategory}
                            careRecipientUID={props.careRecipientUID}
                            onSave={refresh}
                            onDelete={refresh}
                            showRelatedSections={showRelatedSections}
                            IsCollapsed={true}
                            entityName={props.entityName}
                          // theme={
                          //   props.entityName ===
                          //   CareEsioEntity.CarePlan.EntityName
                          //     ? "White"
                          //     : "default"
                          // }
                          />
                        );
                      }
                    })}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="my-3">
              <div className="row gy-3">
                <div className="col-12">
                  <RAFEmptyState
                    //title={`No active ${CareEsioEntity.Assessment.DisplayName}`}
                    title={`No active support need`}
                    body={`Please click on add button to add support need.`}
                    displayStyle="TextOnly"
                  />
                </div>
                {/* <div className="col-12">
                <RAFPermissionRender
                  permissionName={
                    CareAssessmentPermissionConstants.CareAssessmentManage
                  }
                >
                  <RAFButtonComponent
                    type="button"
                    action={RAFButtonConstant.Add}
                    className="e-outline w-100 btn-dashed btn-roundedCorner"
                    isPrimary
                    onClick={() => onClickCareAssessmentSection()}
                  />
                </RAFPermissionRender>
              </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    ) : (
      <div className="container-fluid px-0">
        <ACLoadingPanel loadingText="Preparing Data..." />
      </div>
    );
  };

  let mainTabRef = useRef(null);

  const careAssessmentHeaderItems = [
    {
      text: RAFBPCurrentStatus.Published,
      value: RAFBPCurrentStatus.Published,
    },
    {
      text: RAFBPCurrentStatus.Draft,
      value: RAFBPCurrentStatus.Draft,
    },
  ];

  const renderCareAssessmentTabContent = (selectedItem) => {
    selectedItem = selectedItem.value;

    if (selectedItem === RAFBPCurrentStatus.Published) {
      return renderCareAssessmentSectionCardContent(
        RAFBPCurrentStatus.Published
      );
    } else if (selectedItem === RAFBPCurrentStatus.Draft) {
      return renderCareAssessmentSectionCardContent(RAFBPCurrentStatus.Draft);
    }
  };

  const renderCareAssessmentSectionCardContent = (selectedItem?: string) => {
    const activeCarePlanUID = isNotNullAndUndefined(props.activeCarePlan)
      ? props.activeCarePlan.UID
      : null;

    const currentObject = isNotNullAndUndefined(rafObject)
      ? rafObject.rafObject
      : props.careRecipientRow;

    return (
      <CareAssessmentListContent
        activeCarePlanUID={activeCarePlanUID}
        careRecipientUID={currentObject.UID}
        assessmentCurrentStatus={selectedItem}
        onDeleteChildItem={refresh}
      />
    );
  };

  const onSelectCareAssessmentTab = (e) => {
    if (isNotNullAndUndefined(e) && isNotNullAndUndefined(e.selectedItem)) {
      const selectedItemInnerText = e.selectedItem.innerText;

      const selectedItem = isNotNullAndUndefined(selectedItemInnerText)
        ? careAssessmentHeaderItems.find(
          (x) => x.text === selectedItemInnerText
        )
        : null;
      setState({ selectedTabItem: selectedItem });
      mainTabRef.current.refresh();
    }
  };

  const selectedItemIndex = isNotNullAndUndefined(state.selectedTabItem)
    ? careAssessmentHeaderItems.findIndex(
      (x) => x.value === state.selectedTabItem.value
    )
    : 0;

  const onCurrentStatusDropdownClicked = (args: MenuEventArgs) => {
    if (
      isNotNullAndUndefined(args) &&
      isNotNullAndUndefined(args.item) &&
      isNotNullAndUndefined(args.item.text)
    ) {
      setState({ selectedStatus: args.item.text });
    }
  };

  const careAssessmentSectionCardContent = () => {
    const { careAssessments, showRelatedSections } = state;
    let filteredCareAssessments = isNotNullAndUndefined(careAssessments)
      ? careAssessments.filter(
        (x) => isNotNullAndUndefined(x.CurrentStatus) && x.IsActive === true
      )
      : null;
    let careAssessmentCategory;
    if (isNotNullAndUndefined(careAssessments)) {
      careAssessmentCategory = getUnique(careAssessments, "CategoryUID");
    }

    return uiStyle === "DetailsStyle" ? (
      <div style={{ margin: "0 -1rem" }}>
        <div className="d-flex align-items-center justify-content-between p-2 p-md-3 pt-0 pt-md-0">
          <div>
            <div className="header_5">
              <span>Sections</span>
            </div>
            <div className="body_2">
              <span>All sections and associated actions & risks.</span>
            </div>
          </div>
          <RAFPermissionRender
            permissionName={CareAssessmentPermissionConstants.CareAssessmentManage}
          >
            <div>
              <RAFButtonComponent
                isPrimary
                btnContent={`Add Section`}
                // btnContent={`Add ${CareEsioEntity.CareServiceContract.DisplayName}`}
                action={RAFButtonConstant.Add}
                onClick={() => onClickCareAssessmentSection()}
              ></RAFButtonComponent>
            </div>
          </RAFPermissionRender>
        </div>
        <div className="custom__card__separator custom__card__separator__light"></div>
        {careAssessmentSectionListContent()}
        {false && (
          <div className="col-12">
            <div
              className={`w-100 d-flex justify-content-center${showRelatedSections ? " p-3 pt-0" : ""
                }`}
            >
              <RAFPermissionRender
                permissionName={
                  CareAssessmentPermissionConstants.CareAssessmentManage
                }
              >
                <RAFButtonComponent
                  type="button"
                  action={RAFButtonConstant.Add}
                  isPrimary
                  onClick={() => onClickCareAssessmentSection()}
                  uiMode="FooterDashedOutlineBtn"
                />
              </RAFPermissionRender>
            </div>
          </div>
        )}
      </div>
    ) : (
      <CustomCardWidget removeContentPadding>
        <RAFCollapseWithPlusIcon
          toggleArrowIcon="Arrow"
          title={CareEsioEntity.CareAssessment.CollectionName}
          allowFullRowClick
          isCollapsed={true}
          showSeparator={false}
          collapsePanelHeaderClass="collapsePanel__header__fixedHeight__lg"
          collapsePanelContentClassname="relatedListOuterDiv p-2 p-md-3"
          badge={{
            count:
              IsNotNullOrWhiteSpace(filteredCareAssessments) &&
                IsNotNullOrWhiteSpace(filteredCareAssessments.length)
                ? filteredCareAssessments.length
                : 0,
            show: true,
            className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
          }}
          customButton={
            <div>
              {props.showAddBtn !== false && (
                <div className="d-flex align-items-baseline">
                  <div className="col-auto me-2">
                    <DropDownButtonComponent
                      //cssClass="custom-button-sm text-nowrap primary-custom-button e-caret-hide"
                      iconCss={RAFButtonConstant.Filters.IconCss}
                      className="custom-button-sm primary-custom-button e-caret-hide"
                      items={[
                        { id: "Current", text: "Current" },
                        { id: "Published", text: "Published" },
                        { id: "Draft", text: "Draft" },
                        { id: "History", text: "History" },
                      ]}
                      select={onCurrentStatusDropdownClicked.bind(this)}
                    >
                      {/* <span style={{ fontSize: "14px", fontWeight: 500 }}>
                            {state.selectedStatus}
                          </span> */}
                    </DropDownButtonComponent>
                  </div>
                  <div className="col-auto">
                    <RAFPermissionRender
                      permissionName={
                        CareAssessmentPermissionConstants.CareAssessmentManage
                      }
                    >
                      <RAFButtonComponent
                        className={`custom-button-sm primary-custom-button`}
                        // className={`custom-button-sm primary-custom-button${!BrowserIsDevice ? " min-width-lg" : ""
                        //   }`}
                        // className="custom-button-md"
                        action={RAFButtonConstant.Add}
                        // {...(!BrowserIsDevice
                        //   ? {
                        //     btnContent: `Add ${CareEsioEntity.CareAssessment.DisplayName}`,
                        //   }
                        //   : {})}
                        onClick={() => onClickCareAssessmentSection()}
                      />
                    </RAFPermissionRender>
                  </div>
                </div>
              )}
            </div>
          }
        >
          {careAssessmentSectionContent()}
        </RAFCollapseWithPlusIcon>
      </CustomCardWidget>
    );
  };

  function createCareAssessmentSectionContent() {
    if (state.showCreateCareAssessmentSectionContent === true) {
      const { careAssessments } = state;
      const { activeCarePlan } = props;
      let initialValues = {};
      const currentObject = isNotNullAndUndefined(rafObject)
        ? rafObject.rafObject
        : props.careRecipientRow;

      if (isNotNullAndUndefined(currentObject)) {
        initialValues["care_recipientuid"] = currentObject.UID;
        initialValues["care_recipient"] = currentObject.recipient_name;
      }
      if (isNotNullAndUndefined(activeCarePlan)) {
        initialValues["care_plan"] = activeCarePlan.Title;
        initialValues["care_planuid"] = activeCarePlan.UID;
      }

      initialValues["current_date"] = new Date();
      //initialValues["review_date"] = new Date();
      initialValues["assessment_type"] = "Initial";
      initialValues["status"] = "Completed";
      initialValues["assessment_trigger"] = "Initial";
      initialValues["current_status"] = RAFBPCurrentStatus.Draft;
      initialValues["is_active"] = true;

      /*if (isNotNullAndUndefined(currentObject)) {
        initialValues["CareRecipientUID"] = currentObject.UID;
        initialValues["CareRecipient"] = currentObject.first_name;
      }
      if (isNotNullAndUndefined(activeCarePlan)) {
        initialValues["CarePlan"] = activeCarePlan.Title;
        initialValues["CarePlanUID"] = activeCarePlan.UID;
      }

      initialValues["CurrentDate"] = new Date();
      //initialValues["ReviewDate"] = new Date();
      initialValues["AssessmentType"] = "Initial";
      initialValues["Status"] = "Completed";
      initialValues["AssessmentTrigger"] = "Initial";
      initialValues["CurrentStatus"] = RAFBPCurrentStatus.Draft;
      initialValues["IsActive"] = true;*/

      return (
        <CreateCareAssessment
          initialValues={initialValues}
          onCreatCareAssessment={onCreatCareAssessment}
          onSave={refreshOnCreateCarePlanSection.bind(this)}
          onClose={closeCreateCareAssessmentSectionDialog.bind(this)}
          moduleName={careAssessmentModule}
          isActive
          careAssessments={careAssessments}
          careRecipientUID={props.careRecipientUID}
          {...props}
        />
      );
    } else {
      return <div></div>;
    }
  }

  const onCreatCareAssessment = () => {
    if (state.isCreatedCareAssessment !== true) {
      setState({ isCreatedCareAssessment: true });
    }
  };

  const closeCreateCareAssessmentSectionDialog = () => {
    if (state.isCreatedCareAssessment) {
      refresh();
    } else {
      setState({ showCreateCareAssessmentSectionContent: false });
    }
  };

  const refreshOnCreateCarePlanSection = () => {
    refresh();
  };

  const onClickCareAssessmentSection = () => {
    setState({ showCreateCareAssessmentSectionContent: true });
  };

  const onSelecting = (e) => {
    if (e.isSwiped) {
      e.cancel = true;
    }
  };

  const careAssessmentSectionContent1 = () => {
    return (
      <TabComponent
        ref={mainTabRef}
        cssClass="e-fill header-tab custom-button light-primary fixedHeight_tabHeader borderBottom_tabHeader customTabDirective"
        // cssClass='e-fill custom-tab-footer-border active-bg-gray active-bg-white borderBottom_tabHeader'
        animation={{
          previous: {
            effect: "FadeIn",
            duration: 600,
            easing: "ease",
          },
          next: {
            effect: "FadeIn",
            duration: 600,
            easing: "ease",
          },
        }}
        selected={(e) => onSelectCareAssessmentTab(e)}
        selectedItem={selectedItemIndex}
        selecting={onSelecting}
      >
        <TabItemsDirective>
          {careAssessmentHeaderItems.map((x) => {
            return (
              <TabItemDirective
                key={x.value}
                header={x}
                content={renderCareAssessmentTabContent.bind(this, x)}
              />
            );
          })}
        </TabItemsDirective>
      </TabComponent>
    );
  };

  const careAssessmentSectionContent = () => {
    const activeCarePlanUID = isNotNullAndUndefined(props.activeCarePlan)
      ? props.activeCarePlan.UID
      : null;

    const currentObject = isNotNullAndUndefined(rafObject)
      ? rafObject.rafObject
      : props.careRecipientRow;
    return (
      <CareAssessmentListContent
        key={state.selectedStatus}
        activeCarePlanUID={activeCarePlanUID}
        careRecipientUID={currentObject.UID}
        assessmentCurrentStatus={state.selectedStatus}
        onDeleteChildItem={refresh}
      />
    );
  };

  return (
    <RAFPermissionRender
      permissionName={CareAssessmentPermissionConstants.CareAssessmentRead}
      showDefaultNoPermissionContent
    >
      <div className="row gx-0 gy-3">
        <div className="col-12">
          {state.isLoading === false ? (
            <div>
              {careAssessmentSectionCardContent()}
              {state.showCreateCareAssessmentSectionContent && (
                <DialogComponent
                  header={`Add Section`}
                  // header={`Create ${getDisplayNameByModuleName(carePlanSectionModule, state.carePlanSectionEntity.DisplayName)}`}
                  showCloseIcon
                  visible={state.showCreateCareAssessmentSectionContent}
                  content={createCareAssessmentSectionContent.bind(this)}
                  //cssClass={entity.EntityType === RAFEntityType.UserForm ? "dlg-new-style centerDialog-lg createEditForm form-center-dialog" : "rightDialog createEditForm full-height form-center-dialog"}
                  cssClass={
                    "rightDialog createEditForm full-height form-center-dialog dlg-new-style"
                  }
                  isModal
                  target="body"
                  closeOnEscape={false}
                  close={closeCreateCareAssessmentSectionDialog.bind(this)}
                  id={`dlg_create_${careAssessmentModule}`}
                  zIndex={1200}
                  open={PreventFocusOnDialogOpen}
                ></DialogComponent>
              )}
            </div>
          ) : (
            <div className="container-fluid px-0">
              <ACLoadingPanel loadingText="Preparing Data..." />
            </div>
          )}
        </div>
      </div>
    </RAFPermissionRender>
  );
}
// CareAssessmentList.defaultProps = {
//   showHeaderTitle: false,
//   uiStyle: "CardStyle",
// };

export default React.memo(CareAssessmentList);
