import * as R from "ramda";
import {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { Field, FormRenderProps } from "react-final-form";
import { useRecoilValue } from "recoil";
import { RRule } from "rrule";
import RAFAutoCompleteMUI from "../../../RAFComponents/Inputs/RAFAutoCompleteMUI";
import RAFCheckBox from "../../../RAFComponents/Inputs/RAFCheckBox";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDropdown from "../../../RAFComponents/Inputs/RAFDropdown";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";
import { setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  getSessionStorage,
  setSessionStorage,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import { getCreateComponent } from "../../../RAFComponents/helpers/RAFMenuHelper";
import { getRuleByWeekday, getRuleFrequency } from "../../../RAFComponents/helpers/RRuleInputHelper";
import {
  ConvertToElementID,
  IDialogProps,
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  RAFFormComponentProps,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import { EvaluateAndSetScore } from "../../../RAFMaster/helpers/RMutils";
import { CareInterventionSupportPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  Constants,
  RAFButtonConstant,
  RAFLayout,
  StorageKey,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { RAFBPCurrentStatus } from "../../../constants/Common/RMConstants";
import { atomEditingRecordId } from "../../Common/List/IndexHelper";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../Common/Providers/RAFObjectContextProvider";
import EditContent from "../../Common/Update/EditContent";
import { getAssessmentListByCarePlanAndRecipientUID } from "../CareAssessmentLibrary/CareAssessmentHelper";

interface IProps {
  moduleName?: string;
  onSave?: (entityId?: string, objectName?: string) => void;
  onDelete?: () => void;
  objectUID?: string;
  isActive?: boolean;
  careInterventionSupportRow?: string;
}

interface IState {
  isActive?: boolean;
  careAssessments: any[];
}

function UpdateCareInterventionSupport({
  objectUID,
  moduleName,
  isActive,
  ...props
}: PropsWithChildren<IProps & RAFFormComponentProps & IDialogProps>) {
  let rafForm: FormRenderProps | null;
  let submitButtonRef = useRef(null);

  let objectGUID = useRecoilValue(atomEditingRecordId(moduleName));
  objectUID = isNotNullAndUndefined(objectGUID) ? objectGUID : objectUID;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isActive: false,
      careAssessments: [],
    }
  );

  useEffect(() => {
    refresh();
  }, [isActive, objectUID]);

  const refresh = async () => {
    const { careInterventionSupportRow } = props;
    if (isActive) {
      const careAssessments = await getAssessmentListByCarePlanAndRecipientUID(
        careInterventionSupportRow["CarePlanUID"],
        careInterventionSupportRow["CareRecipientUID"],
        CareEsioEntity.CareAssessment.EntityName,
        IsNullOrWhiteSpace(careInterventionSupportRow["CareAssessmentUID"]) ? RAFBPCurrentStatus.Draft : null
      );

      setState({ isActive, careAssessments });
    } else {
      setState({ isActive });
    }
  };

  const onSubmitObject = (
    values,
    entity,
    queryAttributes,
    saveObject?: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string; }>
  ) => {
    const frequency = getRuleFrequency(values.RruleExpression);
    if (frequency === RRule.WEEKLY) {
      const ruleByWeekday = getRuleByWeekday(values.RruleExpression);
      if (!isNotEmptyArray(ruleByWeekday)) {
        showWarningToast("Please select at least one day");
        return;
      }
    }
    let updatedValues = R.clone(values);
    // if (
    //   isNotNullAndUndefined(entity) &&
    //   isNotNullAndUndefined(entity.EntitySettings) &&
    //   isNotNullAndUndefined(entity.EntitySettings.EnableScore) &&
    //   entity.EntitySettings.EnableScore === true
    // ) {
    //   updatedValues = EvaluateScore(values, queryAttributes);
    // }

    if (isNotNullAndUndefined(queryAttributes)) {
      updatedValues = EvaluateAndSetScore(values, queryAttributes);
    }

    saveObject(updatedValues)
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.objectUID)
        ) {
          showSuccessToast("Saved successfully");

          let allViews: RAFViewRow[] = JSON.parse(
            getSessionStorage(StorageKey.allViews_modulename + moduleName, true)
          );
          let recentlyCreatedView: RAFViewRow =
            allViews && allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
          if (isNotNullAndUndefined(recentlyCreatedView)) {
            setSessionStorage(
              StorageKey.recentViewId_modulename + moduleName,
              true,
              JSON.stringify({
                viewId: recentlyCreatedView.UID,
                viewName: recentlyCreatedView.DisplayName,
              })
            );
          }
          if (props.onSave)
            props.onSave(response.objectUID, response.objectName);
        } else {
          showWarningToast("Sorry something went wrong !");
        }
      })
      .catch((error) => error);
  };

  const enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (
        isNotNullAndUndefined(submitButtonRef) &&
        isNotNullAndUndefined(submitButtonRef.current) &&
        isNotNullAndUndefined(submitButtonRef.current.toggleBtnPermission)
      ) {
        submitButtonRef.current.toggleBtnPermission(g.submitting);
      }
    }
  };

  const getSupportTypeDropdown = () => {
    return (
      <RAFDropdownCC
        field="SupportType"
        label="Support Type"
        placeholder="Select Type"
        isColorOption
        uitype="colorpicker"
        moduleName={CareEsioEntity.CareInterventionSupport.EntityName}
        onChanged={onChangeSupportType}
        emptyString="All"
      />
    );
  };

  const getSupportCategory = () => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    let filter: RAFCustomFilter = {};
    let filterVal: string[] = [];
    filterVal.push("Activity");
    filter.Operator = RAFCustomOperator.Equal;
    filter.Value = filterVal;
    filter.Field = "Type";
    customFilter.Rules.push(filter);
    return (
      <RAFLookUpMUI
        field={"Category"}
        placeholder={"Select Category"}
        //required
        url={"StandardizedLibrary/Lookup"}
        moduleName={RAFEntityName.StandardizedLibrary}
        showCreateButton={false}
        onChanged={(value) => onChangeSupportCategory(value)}
        //formGroupClassName={"mb-0"}
        // createform={getCreateComponent(
        //   RAFEntityName.StandardizedLibrary
        // )}

        SearchCreateOptionMode={"Footer"}
        customFilter={customFilter}
      />
    );
  };

  const onChangeSupportCategory = (value) => {
    setFormValue(rafForm, "Title", null);
    setFormValue(rafForm, "TitleUID", null);
  };

  const onChangeSupportType = () => {
    setFormValue(rafForm, "Title", null);
    setFormValue(rafForm, "TitleUID", null);
  };

  const getTitleFieldCustomFilter = (supportType: string) => {
    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    let filter: RAFCustomFilter = {};
    let filterVal: string[] = [];
    filterVal.push("Support");
    filter.Operator = RAFCustomOperator.Equal;
    filter.Value = filterVal;
    filter.Field = "Type";
    customFilter.Rules.push(filter);

    if (IsNotNullOrWhiteSpace(supportType)) {
      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(supportType);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = "ParentUID";
      customFilter.Rules.push(filter);
    }

    // if (IsNotNullOrWhiteSpace(supportType) && supportType !== "All") {
    //   let filter: RAFCustomFilter = {};
    //   let filterVal: string[] = [];
    //   filterVal.push(supportType);
    //   filter.Operator = RAFCustomOperator.Equal;
    //   filter.Value = filterVal;
    //   filter.Field = "TagsListJson";
    //   customFilter.Rules.push(filter);
    // }
    return customFilter;
  };

  const getTitleLookUpDropdown = () => {
    return (
      <Field name="CategoryUID">
        {({ input }) => {
          const selectedSupportType = input.value;

          return (
            <div key={ConvertToElementID(selectedSupportType)}>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <label className={"form-label required"}>Title</label>
                </div>
                <div className="d-flex align-items-center">
                  <div className="mb-2">
                    <label className={"form-label"}>Must-do</label>
                  </div>
                  <RAFCheckBox
                    field={`IsRequired`}
                    label="Must-do"
                    showLabel={false}
                    validate={false}
                    labelPosition="right"
                    inputFieldClassName="col-auto"
                    labelClassName="col"
                    formGroupClassName="mb-2"
                    rowClassName="row g-2 align-items-center"
                    uitype={"switch"}
                    hideRequiredMessage
                  />
                </div>
              </div>
              {/* <RAFLookUpMUI */}
              <RAFAutoCompleteMUI
                field={"Title"}
                placeholder={"Select Title"}
                required
                showLabel={false}
                url={"StandardizedLibrary/Lookup"}
                moduleName={RAFEntityName.StandardizedLibrary}
                showCreateButton
                //formGroupClassName={"mb-0"}
                createform={getCreateComponent(
                  RAFEntityName.StandardizedLibrary
                )}
                SearchCreateOptionMode={"Footer"}
              //customFilter={getTitleFieldCustomFilter(selectedSupportType)}
              />
            </div>
          );
        }}
      </Field>
    );
  };

  const onSupportNeedSelected = (label, value) => {
    if (isNotNullAndUndefined(value)) {
      let { careAssessments } = state;
      let selectedAssessment = careAssessments.find((x) => x.UID === value);
      if (isNotNullAndUndefined(selectedAssessment)) {
        setFormValue(rafForm, "CareAssessment", selectedAssessment["title"]);
        setFormValue(rafForm, "CareAssessmentUID", selectedAssessment.UID);
        setFormValue(
          rafForm,
          "CareAssessmentType",
          CareEsioEntity.CareAssessment.EntityName
        );
      }
    } else {
      setFormValue(rafForm, "CareAssessment", null);
      setFormValue(rafForm, "CareAssessmentUID", null);
      setFormValue(rafForm, "CareAssessmentType", null);
    }
  };

  const getSupportNeedsDropdown = () => {
    const { careInterventionSupportRow } = props;
    const { careAssessments } = state;
    if (isNotEmptyArray(careAssessments)) {
      return (
        <div className="col-md-12">
          <RAFDropdown
            field={"CareAssessmentUID"}
            showLabel
            //uitype="checkbox"
            onChanged={(lable, value) => onSupportNeedSelected(lable, value)}
            label="Link Support Need"
            //formGroupClassName="mb-0"
            allowFiltering
            disabled={IsNullOrWhiteSpace(careInterventionSupportRow["CareAssessmentUID"]) ? false : true}
            required
          >
            {isNotEmptyArray(careAssessments) && careAssessments.map((opt) => {
              return (
                <RAFChoiceOption key={opt.UID} label={opt.title} value={opt.UID} />
              );
            })}
          </RAFDropdown>
        </div>
      );
    } else {
      return null;
    }
  };

  const getTypeAndTitleInputFiled = () => {
    return (
      <>
        {/* <div className="col-md-12">{getSupportCategory()}</div>
        <div className="col-md-12">{getSupportTypeDropdown()}</div> */}
        <div className="col-md-12">{getTitleLookUpDropdown()}</div>
        {getSupportNeedsDropdown()}
      </>
    );
  };

  if (
    isNotNullAndUndefined(objectUID) &&
    !IsNullOrWhiteSpace(objectUID) &&
    state.isActive
  ) {
    return (
      <div
        className="h-100"
        key={`${objectUID}_${moduleName}`}
        id={`raf_dlg_Outer_Div_${moduleName}`}
      >
        <RAFEntityProvider moduleName={moduleName}>
          <RAFAttributeRelatedListProvider moduleName={moduleName}>
            <RAFAttributesContext.Consumer>
              {({ queryAttributes }) => {
                return (
                  <RAFEntityContext.Consumer>
                    {({ entity }) => {
                      return (
                        <RAFObjectContextProvider
                          moduleName={moduleName}
                          objectId={objectUID}
                          progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                        >
                          <RAFObjectContext.Consumer>
                            {({
                              /*objectId, */ rafObject,
                              error,
                              saveObject,
                            }) => {
                              if (
                                isActive &&
                                isNotNullAndUndefined(rafObject) &&
                                !IsNullOrWhiteSpace(rafObject.UID)
                              ) {
                                let titleCustomFilter: RAFCustomFilter = {}; //StandardizedLibrary
                                titleCustomFilter.Condition = "and";
                                titleCustomFilter.Rules = [];
                                let filter: RAFCustomFilter = {};
                                let filterVal: string[] = [];
                                filterVal.push("Support");
                                filter.Operator = RAFCustomOperator.Equal;
                                filter.Value = filterVal;
                                (filter.Field = "Type"),
                                  titleCustomFilter.Rules.push(filter);

                                let goalCustomFilter: RAFCustomFilter = {}; //StandardizedLibrary
                                if (
                                  isNotNullAndUndefined(rafObject.CarePlanUID)
                                ) {
                                  goalCustomFilter.Condition = "and";
                                  goalCustomFilter.Rules = [];
                                  let filter2: RAFCustomFilter = {};
                                  let filterVal2: string[] = [];
                                  filterVal2.push(rafObject.CarePlanUID);
                                  filter2.Operator = RAFCustomOperator.Equal;
                                  filter2.Value = filterVal2;
                                  (filter2.Field = "CarePlanUID"),
                                    goalCustomFilter.Rules.push(filter2);
                                }

                                return (
                                  <RAFForm
                                    initialValues={rafObject}
                                    formRef={(g) => {
                                      enableSubmitButton(g);
                                      return (rafForm = g);
                                    }}
                                    layout={RAFLayout.TwoColumnLayout}
                                    onSubmit={(values) =>
                                      onSubmitObject(
                                        values,
                                        entity,
                                        queryAttributes,
                                        saveObject
                                      )
                                    }
                                    primaryKey={rafObject.UID}
                                    //decorators={[accountDecorators]}
                                    //convertBeforeSave={ConvertAccountRowToAccountDto}
                                    className="h-100"
                                  >
                                    <div className="e-dlg-content-outer">
                                      <div className="e-dlg-body-content">
                                        {getTypeAndTitleInputFiled()}
                                        <div className="col-md-12">
                                          <EditContent
                                            moduleName={moduleName}
                                            progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                                          />
                                        </div>
                                        {/* <ACLookupAutoComplete
                                                                                label="Title"
                                                                                field="Title"
                                                                                placeholder="Select Title"
                                                                                showLabel
                                                                                //showCreateButton
                                                                                url="DataList/LookUp"
                                                                                createform={RAFCreate}
                                                                                moduleName={RAFEntityName.StandardizedLibrary}
                                                                                SearchCreateOptionMode={"Footer"}
                                                                                customFilter={titleCustomFilter}
                                                                                required
                                                                            />
                                                                            <ACLookup
                                                                                label="Goal"
                                                                                field="Goal"
                                                                                placeholder="Select Goal"
                                                                                showLabel
                                                                                //showCreateButton
                                                                                url="DataList/LookUp"
                                                                                createform={RAFCreate}
                                                                                moduleName={CareEsioEntity.CareGoal.EntityName}
                                                                                customFilter={goalCustomFilter}
                                                                                SearchCreateOptionMode={"Footer"}
                                                                            />
                                                                            <ACDropdown
                                                                                field="Frequency"
                                                                                label="Frequency"
                                                                                placeholder="Select Frequency"
                                                                                moduleName={CareEsioEntity.CareInterventionSupport.EntityName}
                                                                                displayDropdownType="customDropdownBtnForMinCount"
                                                                                uitype="colorpicker"
                                                                            />
                                                                            <ACDropdown
                                                                                field="Priority"
                                                                                label="Priority"
                                                                                placeholder="Select Priority"
                                                                                moduleName={CareEsioEntity.CareInterventionSupport.EntityName}
                                                                                displayDropdownType="customDropdownBtnForMinCount"
                                                                                uitype="colorpicker"
                                                                            /> */}
                                      </div>
                                      <div className="e-dlg-footerContent">
                                        <div className="w-100">
                                          <div className="row gx-2">
                                            {isNotNullAndUndefined(
                                              props.onDelete
                                            ) ? (
                                              <div className="col-auto">
                                                <RAFPermissionRender
                                                  permissionName={
                                                    CareInterventionSupportPermissionConstants.CareInterventionSupportManage
                                                  }
                                                >
                                                  <RAFButtonComponent
                                                    action={
                                                      RAFButtonConstant.Delete
                                                    }
                                                    onClick={props.onDelete}
                                                    idString="EditContent"
                                                    className="e-danger e-outline"
                                                  />
                                                </RAFPermissionRender>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            <div className="col-auto ms-auto">
                                              <RAFPermissionRender
                                                permissionName={
                                                  CareInterventionSupportPermissionConstants.CareInterventionSupportManage
                                                }
                                              >
                                                <RAFButtonComponent
                                                  ref={submitButtonRef}
                                                  isPrimary
                                                  action={
                                                    RAFButtonConstant.Save
                                                  }
                                                  onClick={() =>
                                                    rafForm &&
                                                    rafForm.form.submit()
                                                  }
                                                  idString="EditContent"
                                                  disabled={
                                                    rafForm &&
                                                    rafForm.submitting
                                                  }
                                                />
                                              </RAFPermissionRender>
                                            </div>
                                            <div className="col-auto">
                                              <RAFButtonComponent
                                                action={
                                                  RAFButtonConstant.Cancel
                                                }
                                                onClick={props.onClose}
                                                idString="EditContent"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </RAFForm>
                                );
                              } else {
                                if (error === Constants.RAFObject_NoContent) {
                                  return (
                                    <div className="container-fluid px-0">
                                      <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      className="container-fluid px-0"
                                      style={{ background: "transparent" }}
                                    >
                                      {/* <ACLoadingPanel loadingText="Loading..." /> */}
                                    </div>
                                  );
                                }
                              }
                            }}
                          </RAFObjectContext.Consumer>
                        </RAFObjectContextProvider>
                      );
                    }}
                  </RAFEntityContext.Consumer>
                );
              }}
            </RAFAttributesContext.Consumer>
          </RAFAttributeRelatedListProvider>
        </RAFEntityProvider>
      </div>
    );
  } else {
    return (
      <div
        className="container-fluid px-0"
        style={{ background: "transparent" }}
      ></div>
    );
  }
}
export default UpdateCareInterventionSupport;
