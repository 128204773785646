import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  forwardRef,
  PropsWithChildren,
  Reducer,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
} from "react";
import { FormRenderProps } from "react-final-form";
import ACAutoCompleteMUI from "../../../components/shared/ACFormFields/ACAutoCompleteMUI";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { CareGoalPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  ContentType,
  RAFButtonConstant,
  RAFLayout,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import {
  getAllAttributes,
  getFields,
  getRelatedRecords,
  hideProgress,
  RafClearDialogContent,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  subscribeRAFEvent,
  unsubscribeRAFEvent,
} from "../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  ConvertSystemName,
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined,
  IsNotNullOrWhiteSpace,
  isNullOrUndefined,
} from "../../../RAFComponents/helpers/utils";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapseWithPlusIcon from "../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { getEntityByObjectName } from "../../../RAFMaster/helpers/RMutils";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import RAFCreate from "../../Common/Create";
import { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import { getDefaultCareAssessmentFieldValue } from "../CareAssessmentLibrary/CareAssessmentHelper";
import CareGoalContent from "./CareGoalContent";
import CreateCareGoal from "./CreateCareGoal";

interface IProps {
  careRecipientUID: string;
  careRecipientName: string;
  careAssessmentUID: string;
  showEmptyState?: boolean;
  showAddBtn?: boolean;
  careAssessmentRow: any;
  uiStyle?: "CardStyle" | "DetailsStyle";
}

interface IState {
  isLoading: boolean;
  allCareGoalItems: any;
  listCareGoalItems: any;
  careGoalEntity: EntityRow;
  showCreateCareGoalContent: boolean;
  selectedManageItem: string;
  defaultFieldValue: string;
  selectedParentUID: string;
}

const CareGoalList = forwardRef(function CareGoalList(
  {
    showAddBtn = true,
    showEmptyState = true,
    uiStyle = "CardStyle",
    ...props
  }: PropsWithChildren<IProps>,
  ref
) {
  let rafForm: FormRenderProps | null;
  const careGoalModule = CareEsioEntity.CareGoal.EntityName;
  let careGoalAddDlgRef = useRef<DialogComponent>(null);

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      allCareGoalItems: null,
      listCareGoalItems: null,
      careGoalEntity: null,
      selectedManageItem: null,
      showCreateCareGoalContent: false,
      defaultFieldValue: null,
      selectedParentUID: null,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.careAssessmentUID]);

  useImperativeHandle(ref, () => ({
    toggleCreateDlg() {
      setState({
        showCreateCareGoalContent: !state.showCreateCareGoalContent,
        selectedManageItem: null,
      });
    },
  }));

  useEffect(() => {
    const relatedModule = isNotNullAndUndefined(props.careAssessmentUID)
      ? `${CareEsioEntity.CarePlan.EntityName}_${careGoalModule}`
      : careGoalModule;
    subscribeRAFEvent(
      `${RAFEventName.GridLoad}_${relatedModule}_${props.careRecipientUID}`,
      reLoadListContent
    );

    return () => {
      unsubscribeRAFEvent(
        `${RAFEventName.GridLoad}_${relatedModule}_${props.careRecipientUID}`,
        reLoadListContent
      );
    };
  });

  const reLoadListContent = () => {
    refresh();
  };

  const refresh = async () => {
    await RafClearDialogContent(careGoalAddDlgRef);
    if (
      isNotNullAndUndefined(props.careAssessmentUID) ||
      isNotNullAndUndefined(props.careRecipientUID)
    ) {
      setState({
        isLoading: true,
        allCareGoalItems: null,
        showCreateCareGoalContent: false,
        selectedManageItem: null,
        selectedParentUID: null,
      });


      const [careGoalEntity, attributeRelatedList, queryAttributes] = await Promise.all([
        getEntityByObjectName({
          ObjectName: ConvertSystemName(careGoalModule),
        }),
        getAllAttributes(ConvertSystemName(careGoalModule)),
        getFields(ConvertSystemName(careGoalModule)),
      ]);//dont remove this line  details filed is used in loop below this line add to avoid multiple calls

      const allCareGoalItems = await getCareGoalListItems(careGoalEntity);

      const listCareGoalItems = isNotEmptyArray(allCareGoalItems)
        ? allCareGoalItems.filter((item) => isNullOrUndefined(item.ParentUID))
        : [];

      const defaultFieldValue = await getDefaultCareAssessmentFieldValue(
        props.careAssessmentRow
      );

      setState({
        isLoading: false,
        allCareGoalItems,
        listCareGoalItems,
        defaultFieldValue,
        careGoalEntity,
      });
    }
  };

  const getCareGoalListItems = (careGoalEntity) => {
    return new Promise<any[]>(async (resolve, reject) => {
      const careGoalItems = isNotNullAndUndefined(props.careAssessmentUID)
        ? await getRelatedRecords(
          careGoalModule,
          null,
          "CareAssessmentUID",
          props.careAssessmentUID,
          careGoalEntity.UID,
          null,
          null,
          null
          //customFilter,
        )
        : await getRelatedRecords(
          careGoalModule,
          null,
          "CareRecipientUID",
          props.careRecipientUID,
          careGoalEntity.UID,
          null,
          null,
          null
          //customFilter,
        );
      resolve(careGoalItems);
    });
  };

  const getCareGoalListContent = () => {
    if (uiStyle === "DetailsStyle") {
      return (
        <div className="row g-3">
          <div className="col-12">
            {headerContent()}
          </div>
          {careGoalListItem()}
        </div>
      );
    } else {
      //const showExpandBtn = state.isLoading ? true : isNotEmptyArray(state.careGoalItems) ? true : false;
      return (
        <div className="col-12">
          <CustomCardWidget removeContentPadding>
            <RAFCollapseWithPlusIcon
              toggleArrowIcon="Arrow"
              title={CareEsioEntity.CareGoal.CollectionName}
              allowFullRowClick
              isCollapsed={true}
              showSeparator={false}
              collapsePanelHeaderClass="collapsePanel__header__fixedHeight__lg"
              collapsePanelContentClassname="relatedListOuterDiv p-2 p-md-3"
              badge={{
                count:
                  IsNotNullOrWhiteSpace(state.listCareGoalItems) &&
                    IsNotNullOrWhiteSpace(state.listCareGoalItems.length)
                    ? state.listCareGoalItems.length
                    : 0,
                show: true,
                className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
              }}
              customButton={
                <div>
                  {showAddBtn !== false && (
                    <div className="d-flex align-items-baseline">
                      <div className="col-auto">
                        <RAFPermissionRender
                          permissionName={
                            CareGoalPermissionConstants.CareGoalManage
                          }
                        >
                          <RAFButtonComponent
                            className={`custom-button-sm primary-custom-button`}
                            // className={`custom-button-sm primary-custom-button${!BrowserIsDevice ? " min-width-lg" : ""}`}
                            // className="custom-button-md"
                            action={RAFButtonConstant.Add}
                            // {...!BrowserIsDevice ?
                            //   { btnContent: `Add ${CareEsioEntity.CareGoal.DisplayName}` }
                            //   :
                            //   {}
                            // }
                            onClick={() => onClickAddCareGoal()}
                          />
                        </RAFPermissionRender>
                      </div>
                    </div>
                  )}
                </div>
              }
            >
              <div>
                {createCareGoalDlgDiv()}
                {careGoalListItem()}
              </div>
            </RAFCollapseWithPlusIcon>
          </CustomCardWidget>
          {/* <div className="custom__card overflow-hidden">
            <div className="custom__card__content p-0">
              <RAFCollapse
                title={CareEsioEntity.CareGoal.CollectionName}
                headerRowClassName="with-height"
                // headerRowClassName="with-height bg-card-header-8"
                contentCardClassName="relatedListOuterDiv p-2 p-md-3"
                headerBadge={
                  isNotEmptyArray(state.listCareGoalItems) && (
                    <div className="secondary-text">
                      <span className="e-badge e-badge-grey-border e-badge-notification e-badge-circle e-badge-md ms-2 position-static">
                        {state.listCareGoalItems.length}
                      </span>
                    </div>
                  )
                }
                // removeContentCardPadding
                IsCollapsed={true}
                // IsCollapsed={(state.showCreateCareGoalContent || isNotNullAndUndefined(state.selectedManageItem)) ? false : true}
                customButton={
                  <div>
                    {props.showAddBtn !== false && (
                      <div className="d-flex align-items-baseline">
                        <div className="col-auto">
                          <RAFPermissionRender
                            permissionName={
                              CareGoalPermissionConstants.CareGoalManage
                            }
                          >
                            <RAFButtonComponent
                              className={`custom-button-sm primary-custom-button`}
                              // className={`custom-button-sm primary-custom-button${!BrowserIsDevice ? " min-width-lg" : ""}`}
                              // className="custom-button-md"
                              action={RAFButtonConstant.Add}
                              // {...!BrowserIsDevice ?
                              //   { btnContent: `Add ${CareEsioEntity.CareGoal.DisplayName}` }
                              //   :
                              //   {}
                              // }
                              onClick={() => onClickAddCareGoal()}
                            />
                          </RAFPermissionRender>
                        </div>
                      </div>
                    )}
                  </div>
                }
                removeContentCardPadding

              // Collapsible={showExpandBtn}
              //Collapsible={(state.showCreateCareGoalContent || isNotNullAndUndefined(state.selectedManageItem)) ? false : showExpandBtn}
              //Collapsible={(state.showCreateCareGoalContent || isNotNullAndUndefined(state.selectedManageItem)) ? false : showExpandBtn}
              >
                {createCareGoalDlgDiv()}
                {careGoalListItem()}
              </RAFCollapse>
            </div>
          </div> */}
        </div>
      );
    }
  };

  const headerContent = () => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="header_5">
          {CareEsioEntity.CareGoal.CollectionName}
        </div>
        <div>
          {state.isLoading === false && showAddBtn === true && (
            <div className="col-12 d-flex justify-content-center">
              <RAFPermissionRender
                permissionName={
                  CareGoalPermissionConstants.CareGoalManage
                }
              >
                <RAFButtonComponent
                  action={RAFButtonConstant.Add}
                  onClick={() => onClickAddCareGoal()}
                  className="btn_brand_primary semi_dark"
                />
              </RAFPermissionRender>
              {createCareGoalDlgDiv()}
            </div>
          )}
        </div>
      </div>
    );
  };

  const careGoalListItem = () => {
    if (state.isLoading === false) {
      if (isNotEmptyArray(state.listCareGoalItems)) {
        return (
          <div className="col-12">
            <div className="row gy-2 gy-md-3 gx-0">
              {isNotEmptyArray(state.listCareGoalItems) &&
                state.listCareGoalItems.map((item) => {
                  const subGoalItems = state.allCareGoalItems.filter(
                    (x) => x.ParentUID === item.UID
                  );

                  return (
                    // <div className="col-12" key={item.UID}>
                    <CareGoalContent
                      key={item.UID}
                      careGoalRow={item}
                      careGoalEntity={state.careGoalEntity}
                      onSave={refresh}
                      careRecipientUID={props.careRecipientUID}
                      careRecipientName={props.careRecipientName}
                      subGoalItems={subGoalItems}
                      onClickAddSubGoal={(parentUID) =>
                        onClickAddCareGoal(parentUID)
                      }
                      //showEditItemDiv={state.selectedManageItem}
                      //onEditClick={onSelectManageGoal}
                      isSubGoal
                    />
                    // </div>
                  );
                })}
            </div>
          </div>
        );
      } else if (showEmptyState !== false) {
        return (
          <div className="col-12">
            <RAFEmptyState
              title={`No ${CareEsioEntity.CareGoal.DisplayName} recorded.`}
              {...(showAddBtn === true && {
                body: `To get started, please add a new ${CareEsioEntity.CareGoal.DisplayName} by clicking on the "Add" button.`,
              })}
            />
          </div>
        );
      } else {
        return <div></div>;
      }
    } else {
      return (
        <div className="p-3">
          <div className="row g-3">
            <div className="container-fluid px-0">
              <ACLoadingPanel loadingText="Preparing Data..." />
            </div>
          </div>
        </div>
      );
    }
  };

  //addCareGoal start
  const createCareGoalDlgDiv = () => {
    return (
      state.showCreateCareGoalContent && (
        <DialogComponent
          header={`Add ${CareEsioEntity.CareGoal.DisplayName}`}
          showCloseIcon
          visible={state.showCreateCareGoalContent}
          cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
          id={`dlg_update_${careGoalModule}`}
          content={createCareGoalContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={closeCreateCareGoalDialog.bind(this)}
          open={PreventFocusOnDialogOpen}
          zIndex={1200}
          ref={careGoalAddDlgRef}
        ></DialogComponent>
      )
    );
  };

  const onClickAddCareGoal = (selectedParentUID?: string) => {
    setState({
      showCreateCareGoalContent: true,
      selectedManageItem: null,
      selectedParentUID,
    });
  };

  const createCareGoalContent = () => {
    if (state.showCreateCareGoalContent) {
      let initialValues = getCreateFormInitialValues();
      return (
        <CreateCareGoal
          initialValues={initialValues}
          onSave={onCreateCareGoalList.bind(this)}
          onClose={closeCreateCareGoalDialog.bind(this)}
          moduleName={careGoalModule}
          parentCareGoalUID={state.selectedParentUID}
          isCreateSubGoal={
            isNotNullAndUndefined(state.selectedParentUID) ? true : false
          }
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const onCreateCareGoalList = () => {
    refresh();
  };

  const closeCreateCareGoalDialog = async () => {
    await RafClearDialogContent(careGoalAddDlgRef);
    setState({ showCreateCareGoalContent: false, selectedParentUID: null });
  };

  //addCareGoal end

  const getCreateFormInitialValues = () => {
    let initialValues = {};
    const { careAssessmentRow } = props;
    if (isNotNullAndUndefined(careAssessmentRow)) {
      initialValues["CareRecipientUID"] = careAssessmentRow.CareRecipientUID;
      initialValues["CareRecipient"] = careAssessmentRow.CareRecipient;
      //initialValues['CarePlanSection'] = careAssessmentRow.CarePlan;
      initialValues["CarePlan"] = careAssessmentRow.CarePlan;
      initialValues["CarePlanUID"] = careAssessmentRow.CarePlanUID;
      initialValues["Category"] = careAssessmentRow.Category;
      initialValues["CategoryUID"] = careAssessmentRow.CategoryUID;
      initialValues["SubCategory"] = careAssessmentRow.SubCategory;
      initialValues["SubCategoryUID"] = careAssessmentRow.SubCategoryUID;
      initialValues["CareAssessment"] = state.defaultFieldValue; // careAssessmentRow. ;
      initialValues["CareAssessmentUID"] = careAssessmentRow.UID;
      initialValues["CareAssessmentType"] = careAssessmentRow.Entity;
      initialValues["GoalType"] = "Short Term";
      initialValues["GoalStatus"] = "Not Started";
    } else {
      const objectContext = React.useContext(RAFObjectContext);
      const rafObject = isNotNullAndUndefined(objectContext)
        ? objectContext.rafObject
        : null;
      if (isNotNullAndUndefined(rafObject)) {
        initialValues["CareRecipientUID"] = rafObject.UID;
        initialValues["CareRecipient"] = rafObject.first_name;
        initialValues["GoalType"] = "Short Term";
        initialValues["GoalStatus"] = "Not Started";
      }
    }

    return initialValues;
  };

  const getCreateCareGoalContent = () => {
    let initialValues = getCreateFormInitialValues();

    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];
    let filter: RAFCustomFilter = {};
    let filterVal: string[] = [];
    filterVal.push("Goal");
    filter.Operator = RAFCustomOperator.Equal;
    filter.Value = filterVal;
    filter.Field = "Type";
    customFilter.Rules.push(filter);

    return (
      <div
        className="custom__card"
        id={`create_${CareEsioEntity.CareGoal.EntityName}`}
      >
        <div className="custom__card__content p-3 py-2 pe-2">
          <div className="row align-items-center gx-3">
            <div className="col">
              <RAFForm
                initialValues={initialValues}
                formRef={(g) => (rafForm = g)}
                layout={RAFLayout.TwoColumnLayout}
                onSubmit={onSubmitObject}
                className="h-100"
              >
                <ACAutoCompleteMUI
                  label="Title"
                  field="Title"
                  placeholder="Select Title"
                  showLabel={false}
                  //showCreateButton
                  url="StandardizedLibrary/LookUp"
                  createform={RAFCreate}
                  moduleName={RAFEntityName.StandardizedLibrary}
                  SearchCreateOptionMode={"Footer"}
                  customFilter={customFilter}
                  required
                  formGroupClassName="mb-0"
                />
              </RAFForm>
            </div>
            <div className="col-auto">
              <div className="row gx-2 g-0">
                <div className="col-auto">
                  <RAFButtonComponent
                    isPrimary
                    action={RAFButtonConstant.Save}
                    idString="saveContent"
                    onClick={() => rafForm && rafForm.form.submit()}
                    iconBtn
                  />
                </div>
                <div className="col-auto">
                  <RAFButtonComponent
                    action={RAFButtonConstant.Cancel}
                    onClick={closeCreateCareGoalDialog}
                    idString="cancelContent"
                    iconBtn
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onSubmitObject = (values) => {
    let progressDiv = showProgress(
      `#create_${CareEsioEntity.CareGoal.EntityName}`
    );
    let saveRequestData = getSaveRequest(values, values.UID);
    saveRequestData.EntityUID = state.careGoalEntity.UID;
    repositoryActions
      .postDataAndGetResponse(
        "datalist/save",
        saveRequestData,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          onCreateCareGoalList();
        } else {
          showWarningToast("Sorry something went wrong !");
          onCreateCareGoalList();
        }
      })
      .catch((error) => error);
  };

  const onSelectManageGoal = (itemUID) => {
    setState({ selectedManageItem: itemUID, showCreateCareGoalContent: false });
  };

  const getBodyContent = () => {
    if (
      isNotNullAndUndefined(props.careAssessmentUID) ||
      isNotNullAndUndefined(props.careRecipientUID)
    ) {
      if (showEmptyState === false) {
        if (state.isLoading) {
          return (
            <div className="p-3">
              <div className="row g-3">
                <div className="container-fluid px-0">
                  <ACLoadingPanel loadingText="Preparing Data..." />
                </div>
              </div>
            </div>
          );
        } else {
          if (isNotEmptyArray(state.listCareGoalItems)) {
            return getCareGoalListContent();
          } else {
            return <div>{createCareGoalDlgDiv()}</div>;
          }
        }
      } else {
        return getCareGoalListContent();
      }
    } else {
      return <div></div>;
    }
  };

  return (
    <RAFPermissionRender
      permissionName={CareGoalPermissionConstants.CareGoalRead}
    >
      {getBodyContent()}
    </RAFPermissionRender>
  );
});

export default React.memo(CareGoalList);
