import { DialogUtility } from "@syncfusion/ej2-popups";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import * as R from "ramda";
import { PropsWithChildren, Reducer, useEffect, useReducer } from "react";
import { FormRenderProps } from "react-final-form";
import { ITheme, Model } from "survey-core";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import RAFDatePicker from "../../../RAFComponents/Inputs/RAFDatePicker";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import { getFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  RAFFormComponentProps,
  getSaveRequest,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import { customTheme } from "../../../RAFMaster/RMModules/FormLibrary/components/custom-theme";
import { CareAssessmentPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  Constants,
  RAFButtonConstant,
  RAFLayout,
} from "../../../constants/Common/Constants";
import { RAFBPCurrentStatus } from "../../../constants/Common/RMConstants";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../Common/Providers/RAFObjectContextProvider";
import {
  PublishCareAssessment,
  SaveCareAssessment,
} from "./CareAssessmentHelper";

interface IProps {
  moduleName?: string;
  careAssessmentRow?: any;
  onSave?: (entityId?: string, objectName?: string) => void;
  onClose?: () => void;
  onDelete?: () => void;
  objectUID?: string;
  isActive?: boolean;
  isEditable?: boolean;
}

interface IState {
  isLoading?: boolean;
  careAssessmentRow?: any;
  saveAndPublishPromptContent: boolean;
  formModel: any;
  selectedFormLibrary?: FormLibraryRow;
}

function UpdateCareAssessment({
  objectUID,
  isEditable,
  ...props
}: PropsWithChildren<IProps & RAFFormComponentProps & IDialogProps>) {
  let rafForm: FormRenderProps | null;
  let rafReviewForm: FormRenderProps | null;
  let deleteDialog: any;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      careAssessmentRow: null,
      isLoading: true,
      saveAndPublishPromptContent: false,
      formModel: null,
      selectedFormLibrary: null,
    }
  );

  useEffect(() => {
    if (props.isActive) {
      let progressDiv = showProgress(
        `#dlg_update_${CareEsioEntity.CareAssessment.EntityName}`
      );
      RetrieveRecord(objectUID, CareEsioEntity.CareAssessment.EntityName).then(
        (careAssessmentRow) => {
          if (isNotNullAndUndefined(careAssessmentRow)) {
            careAssessmentRow["reason"] = null;
            careAssessmentRow["Progress"] = null;
          }
          hideProgress(progressDiv);
          setState({
            isLoading: false,
            careAssessmentRow,
          });
        }
      );
    }
  }, [objectUID]);

  let { careAssessmentRow } = state;

  let moduleName =
    isNotNullAndUndefined(careAssessmentRow) &&
      isNotNullAndUndefined(careAssessmentRow.assessment)
      ? careAssessmentRow.assessment
      : CareEsioEntity.CareAssessment.EntityName;

  //Save and Publish prompt starts
  const saveAndPublishPromptDialogOpen = (
    formModel?: any,
    selectedFormLibrary?: FormLibraryRow
  ) => {
    setState({
      saveAndPublishPromptContent: true,
      formModel,
      selectedFormLibrary,
    });
  };
  const saveAndPublishPromptDialogClose = () => {
    setState({
      saveAndPublishPromptContent: false,
      formModel: null,
      selectedFormLibrary: null,
    });
  };

  const onSelectActionStatus = (current_status: string) => {
    const { formModel } = state;

    const reviewDateFormValue = getFormValue(rafReviewForm, "review_date");
    formModel.setValue("review_date", reviewDateFormValue);
    formModel.setValue("current_status", current_status);
    setState({ formModel, saveAndPublishPromptContent: false });
    submitFormData();
  };

  const onSaveActionStatus = (
    formModel: any,
    current_status: string,
    selectedFormLibrary: FormLibraryRow
  ) => {
    formModel.setValue("current_status", current_status);
    submitFormData(formModel, selectedFormLibrary);
  };

  const saveAndPublishContent = () => {
    if (
      isNotNullAndUndefined(state.saveAndPublishPromptContent) &&
      state.saveAndPublishPromptContent === true
    ) {
      const initialValues = getFormValue(rafForm);
      return (
        <RAFForm
          initialValues={initialValues}
          formRef={(g) => {
            return (rafReviewForm = g);
          }}
          layout={RAFLayout.TwoColumnLayout}
          submitOnEnterKey={false}
        >
          <div className="e-dlg-body-content">
            <div className="row gy-3 g-0">
              <div className="col-12">
                <RAFDatePicker
                  field="review_date"
                  label="Review Date"
                  description="Specify the date for reviewing the progress and effectiveness of the interventions or action plan"
                />
              </div>
            </div>
          </div>
          <div className="e-dlg-footerContent pb-2-5">
            <ButtonComponent
              type="button"
              onClick={() => onSelectActionStatus(RAFBPCurrentStatus.Draft)}
              cssClass="form-custom-button"
            >
              Save as Draft
            </ButtonComponent>
            <ButtonComponent
              type="button"
              cssClass="btn_brand_primary semi_dark form-custom-button"
              onClick={() => onSelectActionStatus(RAFBPCurrentStatus.Published)}
            >
              Save & Publish
            </ButtonComponent>
          </div>
        </RAFForm>
      );
    } else {
      return <div></div>;
    }
  };

  //Save and Publish prompt ends

  //delete item start
  const onClickDeleteAssessment = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this) },
      title: `Delete Section`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord() {
    let progressDiv = showProgress(".deleteDialog.e-dialog");
    let { careAssessmentRow } = state;

    let isDeleted = await DeleteRecord(
      objectUID,
      isNotNullAndUndefined(careAssessmentRow.Assessment)
        ? careAssessmentRow.Assessment
        : CareEsioEntity.CareAssessment.EntityName
    );

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      if (isNotNullAndUndefined(props.onDelete)) {
        props.onDelete();
      }
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast("Sorry something went wrong !");
    }
  }
  //delete item end

  const submitFormData = (
    formModel?: any,
    selectedFormLibrary?: FormLibraryRow
  ) => {
    let careAssessment =
      isNotNullAndUndefined(rafForm) && isNotNullAndUndefined(rafForm.values)
        ? rafForm.values
        : null;
    let updatedValues = R.clone(careAssessment);

    let hasCustomForm = false;
    if (
      isNotNullAndUndefined(selectedFormLibrary) &&
      isNotNullAndUndefined(selectedFormLibrary.FormStructure)
    ) {
      hasCustomForm = true;
    }

    if (hasCustomForm) {
      let isFormValid = formModel.validate(true, true);
      if (isFormValid) {
        //set this to full form save
        let formData = formModel.data;

        formModel.getAllQuestions().forEach(function (question) {
          if (formData[question.name] === undefined) {
            formData[question.name] = null; // some empty value you can write result[question.name] = undefined; but it will look weird
          }
        });
        updatedValues = { ...updatedValues, ...formData };

        const current_status = updatedValues.current_status;

        delete updatedValues["tags_list_json"];

        let progressDiv = showProgress(`#raf_dlg_Outer_Div_${moduleName}`);
        let url = "DataList/SaveForm";
        let saveRequestData = getSaveRequest(updatedValues, updatedValues.UID);
        let entityName = selectedFormLibrary.Entity;
        saveRequestData.EntityName = entityName;

        SaveCareAssessment(saveRequestData, url).then(async (objectId) => {
          if (current_status === RAFBPCurrentStatus.Published) {
            const responsePublish = await PublishCareAssessment(
              objectId,
              entityName
            );
          }
          hideProgress(progressDiv);
          if (isNotNullAndUndefined(props.onSave)) {
            props.onSave();
          }
        });
      }
    } else {
      let progressDiv = showProgress(`#raf_dlg_Outer_Div_${moduleName}`);
      let saveRequestData = getSaveRequest(updatedValues, updatedValues.UID);
      let assessmentNotes = updatedValues["AssessmentNotes1"];
      if (isNotNullAndUndefined(assessmentNotes)) {
        updatedValues["AssessmentNotes"] = assessmentNotes;
        delete updatedValues["AssessmentNotes1"];
      }
      delete updatedValues["DueDate"];
      const current_status = updatedValues.current_status;
      let entityName = isNotNullAndUndefined(updatedValues.Assessment)
        ? updatedValues.Assessment
        : CareEsioEntity.CareAssessment.EntityName;
      saveRequestData.EntityName = entityName;

      SaveCareAssessment(saveRequestData).then(async (objectId) => {
        if (current_status === RAFBPCurrentStatus.Published) {
          const responsePublish = await PublishCareAssessment(
            objectId,
            entityName
          );
        }
        hideProgress(progressDiv);
        if (isNotNullAndUndefined(props.onSave)) {
          props.onSave();
        }
      });
    }
  };

  if (IsNotNullOrWhiteSpace(objectUID) && props.isActive) {
    return (
      <div className="h-100">
        <div
          className="h-100"
          key={`${objectUID}_${moduleName}`}
          id={`raf_dlg_Outer_Div_${moduleName}`}
        >
          <RAFEntityProvider moduleName={moduleName}>
            <RAFEntityContext.Consumer>
              {({ entity, formLibrary }) => {
                let hasCustomForm = false;
                if (
                  isNotNullAndUndefined(formLibrary) &&
                  isNotNullAndUndefined(formLibrary.FormStructure)
                ) {
                  hasCustomForm = true;
                }
                let formModel = new Model(formLibrary?.FormStructure);

                formModel.applyTheme(customTheme as ITheme);
                let nextButton =
                  formModel.navigationBar.getActionById("sv-nav-next");
                let prevButton =
                  formModel.navigationBar.getActionById("sv-nav-prev");
                let completeButton =
                  formModel.navigationBar.getActionById("sv-nav-complete");
                nextButton.visible = false;
                prevButton.visible = false;
                completeButton.visible = false;

                formModel.onErrorCustomText.add(function (sender, options) {
                  if (options.name == "required") {
                    //options.text = options.obj.title + " is required";
                    options.text = "This field is required";
                  }
                });

                formModel.data = careAssessmentRow;
                if (isEditable === false) {
                  formModel.mode = "display";
                }

                return (
                  <RAFAttributeRelatedListProvider moduleName={moduleName}>
                    <RAFAttributesContext.Consumer>
                      {({ queryAttributes }) => {
                        let moduelQueryAttributes = queryAttributes;
                        return (
                          <RAFObjectContextProvider
                            moduleName={moduleName}
                            objectId={objectUID}
                            progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                          >
                            <RAFObjectContext.Consumer>
                              {({
                                /*objectId, */ rafObject,
                                error,
                                saveObject,
                              }) => {
                                if (
                                  isNotNullAndUndefined(rafObject) &&
                                  !IsNullOrWhiteSpace(rafObject.UID)
                                ) {
                                  rafObject["reason"] = null;
                                  rafObject["Progress"] = null;
                                  return (
                                    <RAFForm
                                      initialValues={rafObject}
                                      formRef={(g) => {
                                        return (rafForm = g);
                                      }}
                                      layout={RAFLayout.TwoColumnLayout}
                                      primaryKey={rafObject.UID}
                                      //decorators={[accountDecorators]}
                                      //convertBeforeSave={ConvertAccountRowToAccountDto}
                                      className="h-100"
                                    >
                                      <div className="e-dlg-content-outer">
                                        <div className="e-dlg-body-content">
                                          <Survey model={formModel} />
                                        </div>
                                        <div className="e-dlg-footerContent">
                                          <div className="w-100">
                                            <div className="row gx-2">
                                              <div className="col-auto">
                                                {isNotNullAndUndefined(
                                                  props.onDelete
                                                ) && (
                                                    <RAFPermissionRender
                                                      permissionName={
                                                        CareAssessmentPermissionConstants.CareAssessmentManage
                                                      }
                                                    >
                                                      <ButtonComponent
                                                        type="button"
                                                        iconCss="fas fa-trash"
                                                        className="e-control e-btn e-lib e-danger e-outline e-icon-btn"
                                                        onClick={() =>
                                                          onClickDeleteAssessment()
                                                        }
                                                      ></ButtonComponent>
                                                    </RAFPermissionRender>
                                                  )}
                                              </div>

                                              <RAFPermissionRender
                                                permissionName={
                                                  CareAssessmentPermissionConstants.CareAssessmentManage
                                                }
                                              >
                                                {isEditable === true && (
                                                  <div className="col-auto ms-auto">
                                                    {/* <RAFButtonComponent
                                                      isPrimary
                                                      action={
                                                        RAFButtonConstant.Save
                                                      }
                                                      onClick={() =>
                                                        saveAndPublishPromptDialogOpen(
                                                          formModel,
                                                          formLibrary
                                                        )
                                                      }
                                                      idString="EditContent"
                                                      disabled={
                                                        rafForm &&
                                                        rafForm.submitting
                                                      }
                                                    /> */}
                                                    <ButtonComponent
                                                      type="button"
                                                      onClick={() =>
                                                        onSaveActionStatus(
                                                          formModel,
                                                          RAFBPCurrentStatus.Draft,
                                                          formLibrary
                                                        )
                                                      }
                                                      cssClass="form-custom-button"
                                                    >
                                                      Save as Draft
                                                    </ButtonComponent>
                                                    <ButtonComponent
                                                      type="button"
                                                      cssClass="btn_brand_primary semi_dark form-custom-button"
                                                      onClick={() =>
                                                        onSaveActionStatus(
                                                          formModel,
                                                          RAFBPCurrentStatus.Published,
                                                          formLibrary
                                                        )
                                                      }
                                                    >
                                                      Save & Publish
                                                    </ButtonComponent>
                                                  </div>
                                                )}
                                              </RAFPermissionRender>
                                              {isEditable === false && (
                                                <div className="col-auto ms-auto">
                                                  <RAFButtonComponent
                                                    action={
                                                      RAFButtonConstant.Cancel
                                                    }
                                                    onClick={props.onClose}
                                                    idString="EditContent"
                                                  />
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </RAFForm>
                                  );
                                } else {
                                  if (error === Constants.RAFObject_NoContent) {
                                    return (
                                      <div className="container-fluid px-0">
                                        <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        className="container-fluid px-0"
                                        style={{
                                          background: "transparent",
                                        }}
                                      >
                                        {/* <ACLoadingPanel loadingText="Loading..." /> */}
                                      </div>
                                    );
                                  }
                                }
                              }}
                            </RAFObjectContext.Consumer>
                          </RAFObjectContextProvider>
                        );
                      }}
                    </RAFAttributesContext.Consumer>
                  </RAFAttributeRelatedListProvider>
                );
              }}
            </RAFEntityContext.Consumer>
          </RAFEntityProvider>
        </div>
        {state.saveAndPublishPromptContent && (
          <DialogComponent
            header={"Save Section"}
            showCloseIcon
            visible={state.saveAndPublishPromptContent}
            cssClass="centerDialog-sm createEditForm form-center-dialog"
            id="completeStepAlertDialogContent"
            content={saveAndPublishContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={saveAndPublishPromptDialogClose.bind(this)}
            //animationSettings={this.animationSettings}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
          ></DialogComponent>
        )}
      </div>
    );
  } else {
    return (
      <div
        className="container-fluid px-0"
        style={{ background: "transparent" }}
      ></div>
    );
  }
}
export default UpdateCareAssessment;
