import * as R from "ramda";
import React, { Fragment, PropsWithChildren, Reducer, useEffect, useReducer, useRef } from "react";
import { FormRenderProps } from "react-final-form";
import { ITheme, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import RAFIconImage from "../../../RAFComponents/Navigation/RAFIconImage";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  RAFActionMessage,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import { customTheme } from "../../../RAFMaster/RMModules/FormLibrary/components/custom-theme";
import { getPredefinedURLAndUplodToS3 } from "../../../RAFMaster/helpers/RMutils";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareShiftLogPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  RAFActionStatus,
  RAFButtonConstant,
  RAFLayout,
} from "../../../constants/Common/Constants";
import { RAFBPCurrentStatus } from "../../../constants/Common/RMConstants";
import { ServiceTransactionRow } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import UserProfilePhoto from "../../ActiveContacts/User/UserProfilePhoto";
import { getAssessmentListByCarePlanAndRecipientUID } from "../CareAssessmentLibrary/CareAssessmentHelper";
import {
  SaveShiftActivity,
  getShiftActivitiesByTaskId,
} from "../CareShiftLog/CareShiftLogHelper";
import { getCareInterventionSupportListByCareRecipientUID } from "./CareInterventionSupportHelper";

interface IProps {
  serviceTransactionRow?: ServiceTransactionRow;
  onUpdateActiveTabIndex: (activeTabIndex: number) => void;
  shiftActivityFormValue: any;
  allForms: FormLibraryRow[];
  formLibrary: FormLibraryRow;
}

interface IState {
  isLoading: boolean;
  activeCareInterventionSupportList: any[];
  shiftActivities: any[];
  selectedIndex: number;
  selectedSupportNeed: any;
}

function ManageCareInterventionSupport({
  serviceTransactionRow,
  shiftActivityFormValue,
  formLibrary,
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      activeCareInterventionSupportList: [],
      shiftActivities: [],
      selectedIndex: 0,
      selectedSupportNeed: null,
    }
  );

  let rafShiftActivityDetailsForm: FormRenderProps | null;
  const moduleName = CareEsioEntity.CareShiftActivity.EntityName;

  useEffect(() => {
    refesh();
  }, []);

  const refesh = async () => {
    setState({ isLoading: true });
    const [careInterventionSupportRows, careAssessments, shiftActivities] = await Promise.all([
      getCareInterventionSupportListByCareRecipientUID(
        serviceTransactionRow.RelatedToUID,
        CareEsioEntity.CareInterventionSupport.EntityName
      ),
      getAssessmentListByCarePlanAndRecipientUID(
        null,
        serviceTransactionRow.RelatedToUID,
        CareEsioEntity.CareAssessment.EntityName,
        RAFBPCurrentStatus.Published
      ),
      getShiftActivitiesByTaskId(serviceTransactionRow.UID),
    ]);

    const careAssessmentUID = careAssessments ? careAssessments.map((x) => x.UID) : [];

    const activeCareInterventionSupportList = (careInterventionSupportRows && careAssessmentUID) ?
      careInterventionSupportRows.filter((x) => careAssessmentUID.includes(x.CareAssessmentUID)) : [];

    setState({
      isLoading: false,
      activeCareInterventionSupportList,
      shiftActivities,
    });
  };
  const onSupportNeedClicked = (selectedSupportNeed) => {
    setState({ selectedSupportNeed, selectedIndex: 1 });
    onUpdateActiveTabIndex(1);
  };

  const getSupportCardContent = (supportItem) => {
    if (isNotNullAndUndefined(supportItem)) {
      const { shiftActivities } = state;

      const isExist = isNotEmptyArray(shiftActivities)
        ? shiftActivities.some(
          (activity) => activity["support_needuid"] === supportItem.UID
        )
        : false;

      return (
        <div className="col-12">
          <CustomCardWidget
            onClick={() => {
              if (!isExist) {
                onSupportNeedClicked(supportItem);
              }
            }}
            cardClickable
            cardClassName={`${isExist ? "custom__card__active " : ""
              } hover-parent-div`}
            cardContentClassName="p-2-5"
          >
            <div className="row gx-2">
              {BrowserIsDevice ? (
                <div className="col-auto" id={`supportNeed${supportItem.UID}`}>
                  {!isExist ? (
                    <div>
                      <UserProfilePhoto
                        uid={supportItem.UID}
                        relatedToType={
                          CareEsioEntity.CareInterventionSupport.EntityName
                        }
                        canEdit={false}
                        profileImgCss={"profileImg-container-normal"}
                        //progressDivID={`#employee_${employeeAvailabilityRow.EmployeeUID}`}
                        title={supportItem.Title}
                        iconSize="24"
                        fontSize="10"
                        emptyAvatarType="Initial"
                      />
                    </div>
                  ) : (
                    <div>
                      <RAFIconImage
                        iconCssClass="fal fa-check"
                        moduleavatar={`raf-avatar raf-sm circle ${!isExist ? "raf-avatar-grey" : "raf-avatar-primary"
                          }`}
                      ></RAFIconImage>
                    </div>
                  )}
                </div>
              ) : (
                <div className="col-auto" id={`supportNeed${supportItem.UID}`}>
                  {!isExist && (
                    <div className="hover-hide-child-div">
                      <UserProfilePhoto
                        uid={supportItem.UID}
                        relatedToType={
                          CareEsioEntity.CareInterventionSupport.EntityName
                        }
                        canEdit={false}
                        profileImgCss={"profileImg-container-normal"}
                        //progressDivID={`#employee_${employeeAvailabilityRow.EmployeeUID}`}
                        title={supportItem.Title}
                        iconSize="24"
                        fontSize="10"
                        emptyAvatarType="Initial"
                      />
                    </div>
                  )}

                  <div className={!isExist ? "hover-child-div" : ""}>
                    <RAFIconImage
                      iconCssClass="fal fa-check"
                      moduleavatar={`raf-avatar raf-sm circle ${!isExist ? "raf-avatar-grey" : "raf-avatar-primary"
                        }`}
                    ></RAFIconImage>
                  </div>
                </div>
              )}
              <div className="col align-items-center">
                <div className="body_1 medium">
                  <span className="body_1 medium">{supportItem.Title}</span>
                </div>
              </div>
            </div>
          </CustomCardWidget>
        </div>
      );
    } else {
      return null;
    }
  };

  const onUpdateActiveTabIndex = (activeTabIndex: number) => {
    if (props.onUpdateActiveTabIndex) {
      props.onUpdateActiveTabIndex(activeTabIndex);
    }
  };

  let submitButtonRef = useRef(null);

  const enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (
        isNotNullAndUndefined(submitButtonRef) &&
        isNotNullAndUndefined(submitButtonRef.current) &&
        isNotNullAndUndefined(submitButtonRef.current.toggleBtnPermission)
      ) {
        submitButtonRef.current.toggleBtnPermission(g.submitting);
      }
    }
  };

  const onClickBackBtn = () => {
    onUpdateActiveTabIndex(0);
    setState({ selectedIndex: 0 });
  };

  const onSubmitShiftActivity = (
    shiftActivityFormValue: any,
    formModelData: any,
    hasCustomForm: boolean
  ) => {
    const selectedFormLibrary = formLibrary;
    const selectedFormEntityName = formLibrary.Entity;
    // let shiftActivityInitialValues = R.clone(shiftActivityFormValue);
    let updatedValues = R.clone(shiftActivityFormValue);

    if (hasCustomForm) {
      let isFormValid = formModelData.validate(true, true);

      if (isFormValid) {
        let progressDiv = showProgress(`#add_shift_activity_dlg_outerDiv`);
        //commented for use s3 upload by hari
        //uploadFileAndGetFormData(formModelData, moduleName, "create").then(
        getPredefinedURLAndUplodToS3(formModelData, moduleName, "create").then(
          (formData) => {
            //set default values
            if (
              selectedFormLibrary.CategoryType ===
              CareEsioEntity.CareRecipientCQIRegister.DisplayName
            ) {
              formData["add_to_cqi"] = true;
              formData["care_shift_type"] = selectedFormLibrary.Title;
              if (isNullOrUndefined(formData["title"])) {
                formData["title"] = selectedFormLibrary.Title;
              }
            }
            formData["status"] = RAFActionStatus.Done;

            updatedValues = { ...updatedValues, ...formData };
            // console.log("updatedValues", updatedValues);
            // return;
            let saveRequestData = getSaveRequest(
              updatedValues,
              updatedValues.UID
            );
            saveRequestData.EntityName = isNotNullAndUndefined(
              selectedFormEntityName
            )
              ? selectedFormEntityName
              : moduleName;

            let url = "DataList/SaveForm";
            SaveShiftActivity(saveRequestData, url).then(async (response) => {
              if (isNotNullAndUndefined(response)) {
                showSuccessToast(RAFActionMessage.RecordSaved);
                hideProgress(progressDiv);
                if (props.onSave) {
                  props.onSave();
                }
              } else {
                showWarningToast(RAFActionMessage.RecordNotSaved);
                closeDialog();
                hideProgress(progressDiv);
              }
            });
          }
        );
      }
    }
  };

  const closeDialog = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      const { activeCareInterventionSupportList, selectedIndex, selectedSupportNeed } =
        state;

      if (selectedIndex === 0) {
        if (isNotEmptyArray(activeCareInterventionSupportList)) {
          const groupdedByCareAssessmentUID = isNotEmptyArray(
            state.activeCareInterventionSupportList) ?
            state.activeCareInterventionSupportList.reduce((groups, item) => {
              let careAssessmentUID = item.CareAssessmentUID;
              if (!groups[careAssessmentUID]) {
                groups[careAssessmentUID] = [];
              }
              groups[careAssessmentUID].push(item);
              return groups;
            }, {} as Record<string, any[]>) : {};

          if (
            isNotNullAndUndefined(groupdedByCareAssessmentUID) &&
            Object.keys(groupdedByCareAssessmentUID).length > 0
          ) {
            // groupedByCategory is not empty
            return (
              <div className="e-dlg-body-content">
                <div className="row gx-0 gy-3">
                  {Object.keys(groupdedByCareAssessmentUID).map((careAssessmentUID) => {
                    const title = state.activeCareInterventionSupportList.find((x) => x.CareAssessmentUID === careAssessmentUID)?.Category;
                    return (
                      <div key={careAssessmentUID} className="col-12">
                        <CustomCardWidget
                          headerTemplate={
                            <div className="custom__card__header fixed-height">
                              {title}
                            </div>
                          }
                        >
                          <div className="row gx-0 gy-2">
                            {groupdedByCareAssessmentUID[careAssessmentUID].map((item) => (
                              <Fragment key={item.UID}>
                                {getSupportCardContent(item)}
                              </Fragment>
                            ))}
                          </div>
                        </CustomCardWidget>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          } else {
            // groupedByCategory is empty
            return <RAFEmptyState title="You don't have any support needs" />;
          }
        } else {
          return <RAFEmptyState title="You don't have any support needs" />;
        }
      } else {
        let hasCustomForm = false;
        if (
          isNotNullAndUndefined(formLibrary) &&
          isNotNullAndUndefined(formLibrary.FormStructure)
        ) {
          hasCustomForm = true;
        }

        let formModel = new Model(formLibrary?.FormStructure);

        formModel.data = {
          start_date: shiftActivityFormValue.start_date,
          title: selectedSupportNeed.Title,
          support_need: selectedSupportNeed.Title,
          support_needuid: selectedSupportNeed.UID,
          instructions: selectedSupportNeed.Note,
        };

        formModel.applyTheme(customTheme as ITheme);
        let nextButton = formModel.navigationBar.getActionById("sv-nav-next");
        let prevButton = formModel.navigationBar.getActionById("sv-nav-prev");
        let completeButton =
          formModel.navigationBar.getActionById("sv-nav-complete");
        nextButton.visible = false;
        prevButton.visible = false;
        completeButton.visible = false;

        formModel.onErrorCustomText.add(function (sender, options) {
          if (options.name == "required") {
            //options.text = options.obj.title + " is required";
            options.text = "This field is required";
          }
        });

        return (
          <>
            <div className={"e-dlg-body-content p-0"}>
              <div className="mt-0 comments-details-page">
                <div style={{ background: "#fff" }}>
                  <RAFForm
                    formRef={(g) => {
                      enableSubmitButton(g);
                      return (rafShiftActivityDetailsForm = g);
                    }}
                    initialValues={shiftActivityFormValue}
                    layout={RAFLayout.OneColumnLayout}
                    onSubmit={(values) =>
                      onSubmitShiftActivity(values, formModel, hasCustomForm)
                    }
                  >
                    <>
                      {hasCustomForm && (
                        <>
                          <Survey model={formModel} />
                        </>
                      )}
                    </>
                  </RAFForm>
                </div>
              </div>
            </div>
            <div className="e-dlg-footerContent">
              <div className="w-100">
                <div className="row gx-2">
                  <div className="col-auto">
                    <div className="col-auto ms-auto">
                      <RAFButtonComponent
                        type="button"
                        action={RAFButtonConstant.Previous}
                        onClick={onClickBackBtn}
                        idString="next_Content"
                        className="e-outline"
                      />
                    </div>
                  </div>
                  <div className="col-auto ms-auto">
                    <RAFPermissionRender
                      permissionName={
                        CareShiftLogPermissionConstants.CareShiftLogAdd
                      }
                    >
                      <RAFButtonComponent
                        type="button"
                        isPrimary
                        action={RAFButtonConstant.Save}
                        onClick={() =>
                          rafShiftActivityDetailsForm &&
                          rafShiftActivityDetailsForm.form.submit()
                        }
                        ref={submitButtonRef}
                        idString="CreateContent"
                        disabled={
                          rafShiftActivityDetailsForm &&
                          rafShiftActivityDetailsForm.submitting
                        }
                      />
                    </RAFPermissionRender>
                  </div>
                  <div className="col-auto">
                    <RAFButtonComponent
                      type="button"
                      action={RAFButtonConstant.Cancel}
                      onClick={props.onClose}
                      idString="CreateContent"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}

export default React.memo(ManageCareInterventionSupport);
